import './MachineOperatingStatus.css'

import ListItemInput from './ListItemInput'
import ListItemNumberInput from './ListItemNumberInput'
import InfoBox from '../../common/InfoBox'
import { cutText } from '../../utils/Utils'

import React, { useState, useEffect } from 'react'

const MachineOperatingStatus = (props) => {
  const [status, setStatus] = useState(props.value)

  useEffect(() => {
    setStatus(props.value)
  }, [props.value])

  const handleRow = (row, idx) => {
    let w = status.concat()

    row.vehicleType = cutText(row.vehicleType, 16)
    row.tonnage = cutText(row.tonnage, 10)
    row.description = cutText(row.description, 16)
    w[idx] = row
    setStatus(w)
    props.onChangeMachineOperatingStatus(w)
  }

  return (
    <div className="MachineOperatingStatus">
      <InfoBox title="機械関係稼働状況" style={{ width: "100%" }} />
      <table className={"dataList"}>
        <thead className="scrollHead">
          <tr>
            <th className="vehicleType">車種</th>
            <th className="tonnage">ＴＯＮ数</th>
            <th className="vehicleCount">台数</th>
            <th className="description">内容</th>
          </tr>
        </thead>
        <tbody className={"scrollBody"}>
          {status.map((row, idx) => {
            return (
              <tr key={idx} style={{ verticalAlign: "top" }}>
                <td className="vehicleType">
                  <ListItemInput name="vehicleType" readOnly={props.readOnly} row={row} idx={idx} onChangeRow={handleRow} />
                </td>
                <td className="tonnage">
                  <ListItemInput name="tonnage" readOnly={props.readOnly} row={row} idx={idx} onChangeRow={handleRow} />
                </td>
                <td className="vehicleCount">
                  <ListItemNumberInput name="vehicleCount" readOnly={props.readOnly} row={row} idx={idx} onChangeRow={handleRow} />
                </td>
                <td className="description">
                  <ListItemInput name="description" readOnly={props.readOnly} row={row} idx={idx} onChangeRow={handleRow} />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default MachineOperatingStatus
