import { http } from "./Config";

const requestPath = "/v1/task";

export const load = (condition) => {
    return http.get(`${requestPath}`)
}

const exports = {
    load,
}

export default exports;
