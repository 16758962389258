import './SafetyInspection.css'

import LimitedTextarea from '../../common/LimitedTextarea'
import { safetyInspectionState } from '../../hooks/SafetyInspectionState'

import React, { useState, useEffect } from 'react'

import { useRecoilValue } from 'recoil'

const SafetyInspection = (props) => {
  const safetyInspectionMst = useRecoilValue(safetyInspectionState)
  const [correctiveAction, setCorrectiveAction] = useState(props.correctiveAction)
  const [categoryRowSpan, setCategoryRowSpan] = useState(new Map())
  const [totalRowSpan, setTotalRowSpan] = useState(0)
  const [safetyInspection, setSafetyInspection] = useState(props.value)

  useEffect(() => {
    let m = new Map()
    let t = 0
    safetyInspectionMst.forEach((row) => {
      if (m.get(row.category)) {
        m.set(row.category, m.get(row.category) + row.rowSpan)
      } else {
        m.set(row.category, row.rowSpan)
      }
      t += row.rowSpan
    })
    setCategoryRowSpan(m)
    setTotalRowSpan(t)
  }, [safetyInspectionMst])

  const createMap = (safetyInspactionItems) => {
    let map = new Map()
    safetyInspactionItems.forEach((si) => {
      map.set(si.category + "-" + si.item, si.status)
    })
    return map
  }

  let c = []
  let sis = createMap(safetyInspection)

  return (
    <div className="SafetyInspectionV2">
      <table>
        {safetyInspectionMst.map((row, idx) => {
          let rowSpan = 0
          if (c.indexOf(row.category) < 0) {
            rowSpan = categoryRowSpan.get(row.category)
          }
          c.push(row.category)

          let key = row.category + "-" + row.item
          let rowStatus = sis.get(key)
          rowStatus = rowStatus === undefined ? null : rowStatus

          let statusString = rowStatus === 0 ? "ok" : rowStatus === 1 ? "ng" : rowStatus === 2 ? "disabled" : "initial"

          return (
            <React.Fragment>
              <tr className="safetyInspectionRow" key={key}>
                {rowSpan > 0 ? <td className="categoryName" rowSpan={rowSpan}><span>{row.categoryName}</span></td> : <React.Fragment />}
                <td className="description" rowSpan={row.rowSpan}>{row.description}</td>
                <td className={"status " + statusString} rowSpan={row.rowSpan}
                  style={{ cursor: !props.readOnly ? "pointer" : null }}
                  onClick={(e) => {
                    if (!props.readOnly) {
                      let status = rowStatus === null ? 0 : rowStatus + 1
                      if (status === 3) {
                        status = null
                      }

                      let s = safetyInspection.concat()
                      let rec = s.find((r) => {
                        return row.category === r.category && row.item === r.item
                      })

                      if (!rec) {
                        s.push({
                          category: row.category,
                          item: row.item,
                          status: status
                        })
                      } else {
                        rec.status = status
                      }

                      console.log(s)
                      setSafetyInspection(s)
                      props.onChangeSafetyInspectionItems(s)
                    }
                  }}
                ></td>
                {idx === 0 ? <td className="correctiveAction" rowSpan={totalRowSpan}>
                  <LimitedTextarea
                    maxLines={50}
                    maxCharsPerLine={14}
                    initialText={correctiveAction}
                    readOnly={props.readOnly}
                    onChange={(text) => {
                      setCorrectiveAction(text)
                    }}
                    onBlur={(e) => {
                      props.onChangeCorrectiveAction(correctiveAction)
                    }}
                  />
                </td> : <React.Fragment />}
              </tr>
              {
                row.rowSpan > 1 ? [...Array(row.rowSpan - 1)].map((n) => {
                  return (
                    <tr key={key + "-" + n}></tr>
                  )
                }) : <React.Fragment />
              }
            </React.Fragment>
          )
        })}
      </table>
    </div>
  )
}

export default SafetyInspection