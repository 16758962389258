import MeService from '../../services/MeService'
import { sessionUserState } from '../hooks/SessionUserState'

import React, { useEffect } from 'react'
import { useRecoilState } from 'recoil'

const SessionHandler = (props) => {
  let [state, setState] = useRecoilState(sessionUserState)

  useEffect(() => {
    Promise.all([
      MeService.find(),
      MeService.findPersonalSetting()
    ])
      .then(
        response => {
          let userData = response[0].data
          let personalSetting = response[1].data

          if (personalSetting) {
            userData.personalSetting = personalSetting
          }

          setState(userData);
        },
        error => {
          console.log(error);
        }
      );
  }, []);

  return (
    (state.userId) ? (
      props.children
    ) : (
      <React.Fragment />
    )
  )
}

export default SessionHandler