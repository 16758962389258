import { http } from "./Config"

const requestPath = "/v1/projects"

const findAll = (query, paging, fields) => {
  let params = [];

  if (fields !== null && fields.length) {
    params.push("fields=" + fields.join(','))
  }
  if (paging) {
    if (paging.limit) {
      params.push(`limit=${paging.limit}`)
      const offset = paging.limit * (paging.page - 1)
      params.push(`offset=${offset}`)
    }

    paging.sort.forEach((s) => {
      params.push(`sort=${s.field},${s.direction}`)
    })
  }
  if (query) {
    params.push("query=" + encodeURIComponent(query))
  }

  return http.get(`${requestPath}/${params.length ? "?" + params.join("&") : ""}`)
};

const favoriteConstInfo = () => {
  return http.get(`${requestPath}/favorite`)
}

const constInfo = (constId) => {
  return http.get(`${requestPath}/constinfo/${constId}`)
}

const exports = {
  findAll,
  favoriteConstInfo,
  constInfo,
};

export default exports
