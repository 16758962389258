import { http } from "./Config";

const requestPath = "/v1/constreports";

export const create = (constData) => {
    return http.post(`${requestPath}`, constData, {
        headers: {
            "content-type": "application/json",
        },
    })
}

export const check = (constData) => {
    return http.post(`${requestPath}/check`, constData, {
        headers: {
            "content-type": "application/json",
        },
    })
}

export const loadById = (id) => {
    return http.get(`${requestPath}/${id}`)
}

export const load = (condition) => {
    return http.get(`${requestPath}?today=${condition.today}&next=${condition.next}&constId=${condition.constId}&userId=${condition.userId}`)
}

export const loadDedura = (condition) => {
    return http.get(`${requestPath}/dedura`)
}

export const loadJuki = (condition) => {
    return http.get(`${requestPath}/juki`)
}

const exports = {
    create,
    check,
    load,
    loadById,
    loadDedura,
    loadJuki
}

export default exports;
