import { http } from "./Config"

const requestPath = "/v2/constreports"

const findAll = () => {
  return http.get(`${requestPath}/safetymst`)
}

const exports = {
  findAll,
}

export default exports
