import { http } from "./Config";

const requestPath = "/v1/quote";
export const getQuote = (constId) => {
    return http.get(`${requestPath}/?constId=${constId}`)
}

export const saveQuote = (quotationData) => {
    return http.post(`${requestPath}/`, quotationData, {
        headers: {
            "content-type": "application/json",
        },
    })
}
const exports = {
    getQuote,
    saveQuote,
}

export default exports;
