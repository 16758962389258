import { Button, IconButton } from '@mui/material'
import styled from '@emotion/styled';
import { pink, green, blue } from '@mui/material/colors'

export const RedButton = styled(Button)`
  color: white;
  background-color: ${pink[500]};
  :hover {
    background-color: ${pink[700]};
  }
`

export const GreenButton = styled(Button)`
  color: white;
  background-color: ${green[500]};
  :hover {
    background-color: ${green[700]};
  }
`

export const BlueButton = styled(Button)`
  color: white;
  background-color: ${blue[500]};
  :hover {
    background-color: ${blue[700]};
  }
`

export const RedIconButton = styled(IconButton)`
  color: white;
  background-color: ${pink[500]};
  :hover {
    background-color: ${pink[700]};
  }
  height: 25px;
  width: 25px;
  margin: 0px;
  padding: 0px 0px 0px 0px;
  text-align: center;
  vertical-align: middle;
`

export const GreenIconButton = styled(IconButton)`
  color: white;
  background-color: ${green[500]};
  :hover {
    background-color: ${green[700]};
  }
  height: 25px;
  width: 25px;
  margin: 0px;
  padding: 0px 0px 0px 0px;
  text-align: center;
  vertical-align: middle;
  position: relative;
`

export const BlueIconButton = styled(IconButton)`
  color: white;
  background-color: ${blue[500]};
  :hover {
    background-color: ${blue[700]};
  }
  height: 25px;
  width: 25px;
  margin: 0px;
  padding: 0px 0px 0px 0px;
  text-align: center;
  vertical-align: middle;
  position: relative;
`
