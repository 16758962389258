import './RiskPrediction.css'

import CellInput from '../../common/CellInput'

import React, { useState, useEffect } from 'react'
import { MediaPc, MediaSmartPhone } from '../../common/Responsive'

const RiskPrediction = (props) => {
    const [riskPrediction, setRiskPrediction] = useState(() => {
        // 初回レンダリング時のみ処理
        return checkRows(props.value) // 表の最下行に空行を追加する
    })

    useEffect(() => {
        setRiskPrediction(checkRows(props.value))
    }, [props.value])

    const possibilityList = [
        "5:頻繁に起こる",
        "4:かなり起こる",
        "3:たまに起こる",
        "2:あまり起こらない",
        "1:起こりそうにない",
    ]

    const severityList = [
        "5:死亡、失明、手足の切断等の重篤災害",
        "4:骨折等長期治療が必要な休業災害（休業４日以上）",
        "3:上記以外の休業災害（休業３日以内）",
        "2:軽い切り傷、打撲（医師の措置不要）",
        "1:治療不要（無傷）",
    ]

    return (
        <div className="RiskPrediction">
            <datalist id="possibilityList">
                {possibilityList.map(v => (<option key={v} value={v} />))}
            </datalist>
            <datalist id="severityList">
                {severityList.map(v => (<option key={v} value={v} />))}
            </datalist>
            <table className={"dataList"}>
                <thead className={"scrollHead"}>
                    <tr>
                        <th className={"number"}></th>
                        <th className={"where"} style={{ textAlign: "center" }}>
                            <span className="riskPText" style={{ fontWeight: "initial" }}>どこで何をしていて</span>
                            <span className="riskPText" style={{ fontWeight: "initial" }}>どのような</span>
                        </th>
                    </tr>
                </thead>
                <tbody className={"scrollBody"}>
                    {riskPrediction.map((row, idx) => {
                        getCalc(row)
                        return (
                            <React.Fragment>
                                <tr>
                                    <td className={"number"} rowSpan={2}>
                                        <span>{idx + 1}.</span>
                                    </td>
                                    <td className={"where"}>
                                        <MediaPc>
                                            <CellInput value={row.where} style={{ width: "180px", marginRight: "7px" }} readOnly={props.readOnly}
                                                maxLength={30}
                                                onChange={(event) => {
                                                    let v = event.target.value
                                                    riskPrediction[idx].where = v
                                                    setRiskPrediction(checkRows(riskPrediction))
                                                    props.onChangeRiskPrediction(trimRow(riskPrediction))
                                                }}
                                            />
                                            <CellInput value={row.what} style={{ width: "180px" }} readOnly={props.readOnly}
                                                maxLength={40}
                                                onChange={(event) => {
                                                    let v = event.target.value
                                                    riskPrediction[idx].what = v
                                                    setRiskPrediction(checkRows(riskPrediction))
                                                    props.onChangeRiskPrediction(trimRow(riskPrediction))
                                                }}
                                            />
                                        </MediaPc>
                                        <MediaSmartPhone>
                                            <CellInput value={row.where} style={{ width: "155px" }} readOnly={props.readOnly}
                                                maxLength={30}
                                                onChange={(event) => {
                                                    let v = event.target.value
                                                    riskPrediction[idx].where = v
                                                    setRiskPrediction(checkRows(riskPrediction))
                                                    props.onChangeRiskPrediction(trimRow(riskPrediction))
                                                }}
                                            />
                                            <CellInput value={row.what} style={{ width: "155px" }} readOnly={props.readOnly}
                                                maxLength={40}
                                                onChange={(event) => {
                                                    let v = event.target.value
                                                    riskPrediction[idx].what = v
                                                    setRiskPrediction(checkRows(riskPrediction))
                                                    props.onChangeRiskPrediction(trimRow(riskPrediction))
                                                }}
                                            />
                                        </MediaSmartPhone>
                                    </td>
                                </tr>
                                <tr style={{ borderBottom: "1px solid gray" }}>
                                    <td className={"evaluation"}>
                                        <span style={{ marginRight: "2px", }} className="miniLabel">可能性</span>
                                        <CellInput value={row.possibility} readOnly={props.readOnly} style={{ textAlign: "center", width: "40px" }} list="possibilityList"
                                            onChange={(event) => {
                                                let v = event.target.value.substring(0, 1)
                                                riskPrediction[idx].possibility = v
                                                setRiskPrediction(checkRows(riskPrediction))
                                                props.onChangeRiskPrediction(trimRow(riskPrediction))
                                            }}
                                        />
                                        <span style={{ marginLeft: "2px", marginRight: "2px", }} className="miniLabel">重篤性</span>
                                        <CellInput value={row.severity} readOnly={props.readOnly} style={{ textAlign: "center", width: "40px" }} list="severityList"
                                            onChange={(event) => {
                                                let v = event.target.value.substring(0, 1)
                                                riskPrediction[idx].severity = v
                                                setRiskPrediction(checkRows(riskPrediction))
                                                props.onChangeRiskPrediction(trimRow(riskPrediction))
                                            }}
                                        />
                                        <span style={{ marginLeft: "2px", marginRight: "2px", }} className="miniLabel">計算</span>
                                        <CellInput value={row.calc} style={{ textAlign: "center", width: "40px" }} readOnly={true} />
                                        <span style={{ marginLeft: "2px", marginRight: "2px", }} className="miniLabel">評価</span>
                                        <CellInput value={row.evaluation} style={{ textAlign: "center", width: "35px" }} readOnly={true} />
                                    </td>
                                </tr>
                            </React.Fragment>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

const getCalc = (row) => {
    let possibility = Number.parseInt(row.possibility)
    let severity = Number.parseInt(row.severity)

    const evaluations = [
        { lower: 20, high: 9999, result: "A" },
        { lower: 11, high: 19, result: "B" },
        { lower: 8, high: 10, result: "C" },
        { lower: 4, high: 7, result: "D" },
        { lower: 0, high: 3, result: "E" }
    ]

    let calc = ''
    let evaluation = ''
    if (possibility && severity) {
        calc = possibility * severity
        let e = evaluations.find((r) => { return (r.lower <= row.calc && row.calc <= r.high) })
        evaluation = (e !== undefined) ? e.result : ''
    }
    row.calc = calc
    row.evaluation = evaluation
}

const isEmpty = (o) => {
    return (o.where === '' && o.what === '' && o.possibility === '' && o.severity === '')
}

const newData = { where: '', what: '', possibility: '', severity: '', calc: '', evaluation: '' }

const trimRow = (values) => {
    return values.slice().filter((row) => { return !isEmpty(row) }).map(v => v)
}
const checkRows = (values) => {
    if (!values) {
        values = []
    }
    values = trimRow(values)
    if (values.length < 3) {
        values.push({ ...newData })
    }
    return values
}


export default RiskPrediction
