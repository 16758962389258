import { http } from "./Config"
const requestPath = "/v1/quote"

/**
 * pdfを作成してダウンロードする
 * @param  data テンプレート変数として利用するデータ
 */
const exportCsv = (data) => {
  if (window.matchMedia && window.matchMedia('(max-device-width: 480px)').matches) {
    return downloadSmartPhone(data)
  } else {
    return downloadPc(data)
  }
}

const importCsv = (constId, data) => {
  const form = new FormData()
  form.append('upload_file', data)

  return http.post(`${requestPath}/csvimport?constId=${constId}`, form)
}

const downloadSmartPhone = (data) => {
  return http.post(`${requestPath}/csvexport`, data, {
    headers: {
      "content-type": "application/json",
    },
    responseType: "arraybuffer",
  })
    .then(
      response => {
        const blob = new Blob([response.data], {
          type: "text/csv"
        });

        var url = URL.createObjectURL(blob)

        //レスポンスヘッダからファイル名を取得
        const fileName = response.headers["content-disposition"].split("filename=")[1].replace(/"/g, "")

        var a = document.createElement("a")
        document.body.appendChild(a)

        a.style = "display: none"
        a.href = url
        a.download = fileName
        a.click()
      },
      error => {
        throw error;
      }
    )
}

const downloadPc = (data) => {
  return http.post(`${requestPath}/csvexport`, data, {
    headers: {
      "content-type": "application/json",
      "accept": "text/csv"
    },
    responseType: "arraybuffer",  // ここが肝。サーバーからShift-JISなファイルを返しても、text/csvではUTF-8に変換されてしまう
  })
    .then(
      response => {
        const blob = new Blob([response.data], {
          //type: response.data.type
          type: "text/csv"
        });

        //レスポンスヘッダからファイル名を取得
        const fileName = response.headers["content-disposition"].split("filename=")[1].replace(/"/g, "")

        const a = document.createElement('a')
        a.href = URL.createObjectURL(blob)
        a.download = fileName

        a.click()
      },
      error => {
        throw error;
      }
    )
}

const exports = {
  importCsv, exportCsv,
};

export default exports;
