import { http } from "./Config"
const requestPath = "/v1/excel"

/**
 * pdfを作成してダウンロードする
 * @param  templateName テンプレート名 stage
 * @param  data テンプレート変数として利用するデータ
 */
const download = (templateName, data) => {
  if (window.matchMedia && window.matchMedia('(max-device-width: 480px)').matches) {
    return downloadSmartPhone(templateName, data)
  } else {
    return downloadPc(templateName, data)
  }
}

const downloadSmartPhone = (templateName, data) => {
  return http.post(`${requestPath}/${templateName}`, data, {
    headers: {
      "content-type": "application/vnd.ms-excel",
    },
    responseType: "blob",
  })
    .then(
      response => {
        const blob = new Blob([response.data], {
          type: "application/vnd.ms-excel"
        });

        var url = URL.createObjectURL(blob)

        var a = document.createElement("a")
        document.body.appendChild(a)

        a.style = "display: none"
        a.href = url
        a.download = templateName + ".xlsx"
        a.click()
      },
      error => {
        throw error;
      }
    )
}

const downloadPc = (templateName, data) => {
  return http.post(`${requestPath}/${templateName}`, data, {
    headers: {
      "content-type": "application/json",
      "accept": "application/vnd.ms-excel"
    },
    responseType: "blob",
  })
    .then(
      response => {
        const blob = new Blob([response.data], {
          //type: response.data.type
          type: "application/vnd.ms-excel"
        });

        //レスポンスヘッダからファイル名を取得
        const fileName = response.headers["content-disposition"].split("filename=")[1].replace(/"/g, "")

        const a = document.createElement('a')
        a.href = URL.createObjectURL(blob)
        a.download = fileName

        a.click()
      },
      error => {
        throw error;
      }
    )
}

const exports = {
  download,
};

export default exports;
