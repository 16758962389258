import './MonthlyTargetVolume.css'

import LimitedTextarea from '../../common/LimitedTextarea'
import React, { useState } from 'react'

const MonthlyTargetVolume = (props) => {
  const [value, setValue] = useState(props.value)
  return (
    <div className="MonthlyTargetVolume constRow" >
      <div className="constTitle">
        <span>毎月末の<tr />出来高目標</span>
      </div>
      <div className="constCell">
        <LimitedTextarea
          maxLines={2}
          maxCharsPerLine={40}
          initialText={value}
          readOnly={props.readOnly}
          style={{ width: "calc(100% - 10px)", margin: "2px", height: "46px" }}
          onChange={(text) => {
            console.log(text)
            setValue(text)
          }}
          onBlur={(e) => {
            props.onChangeValue(value)
          }}
        />
      </div>
    </div>

  )
}

export default MonthlyTargetVolume