import './ViewModeChoiser.css'
import { ButtonGroup, Button, } from '@mui/material'
import { ViewMode } from 'gantt-task-react'

import React from 'react'

const ViewModeChoiser = (props) => {
  return (
    <div className="ViewModeChoiser">
      <ButtonGroup className="ButtonGroup" variant="contained" color="inherit" style={{ color: "gray" }}>
        <Button size="small" onClick={() => {
          props.onChangeViewMode(ViewMode.Day)
        }}>日</Button>
        <Button size="small" onClick={() => {
          props.onChangeViewMode(ViewMode.Week)
        }}>週</Button>
        <Button size="small" onClick={() => {
          props.onChangeViewMode(ViewMode.Month)
        }}>月</Button>
      </ButtonGroup>
    </div>
  )
}

export default ViewModeChoiser
