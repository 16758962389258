import ConfirmationDialog from '../common/ConfirmationDialog'
import { MediaPc } from '../common/Responsive'

import ConstPhotoService from '../../services/ConstPhotoService'

import { errorState } from '../hooks/ErrorState'
import { messageState } from '../hooks/MessageState'
import { constPhotoState } from '../hooks/ConstPhotoState'

import React, { useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import { Box, Button } from '@mui/material'

const FooterControls = (props) => {
    const data = useRecoilValue(constPhotoState)
    const [windowState, setWindowState] = useState(false)
    const [saveData, setSaveData] = useState({})
    const setError = useSetRecoilState(errorState) // エラーメッセージ表示用
    const setMessage = useSetRecoilState(messageState) // メッセージ表示用

    const displayMessage = (title, msg) => {
        setMessage({ msg: msg, open: true, title: title })
    }

    const saveConstPhoto = (data) => {
        ConstPhotoService.save(data) // 該当工事の写真を全件取得
            .then(
                response => {
                    // props.onSaveComplete(data.constId)
                    displayMessage('工事写真編集', `保存を完了しました。`)
                    setWindowState(false)
                },
                error => {
                    setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            ).then(
        ).catch(error => {
            setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
        })
    }

    const buildSaveData = (saveData) => {
        const datas = new Map()

        Object.keys(props.connector).filter((k) => { return k !== "isDrag" }).forEach((k) => {
            let fn = props.connector[k].getData
            let rowData = fn()
            datas[k] = rowData
        })

        saveData = {
            _id: data._id,
            constId: data.constId,
            allPhotos2: { ...datas.allPhotos, photos: datas.allPhotos.photos.map((row) => { return { photoId: row.photoId, deleted: row.deleted } }) },
            allPhoto: [],
            categories: [],
            userAllocated: [],
            trashBox: datas.trashBox.photos
        }

        let userAllocatedkeys = data.userAllocated.map((row) => { return row.constCategory })
        Object.keys(datas).forEach((key) => {
            let row = datas[key]
            let d = {
                ...row,
                photos: row.photos?.map((photo) => {
                    return {
                        photoId: photo.photoId,
                        deleted: photo.deleted,
                        day: photo.day,
                        constClassName: photo.constClassName,
                        constTypeName: photo.constTypeName,
                        floor: photo.floor,
                        description: photo.description,
                        pageBreak: photo.pageBreak,
                    }
                })
            }
            if (row.key?.startsWith("category-")) {
                saveData.categories.push(d)
            } else if (row.key?.startsWith("user-") && userAllocatedkeys.indexOf(row.key) > -1) {
                saveData.userAllocated.push(d)
            }
        })

        return saveData
    }

    return (
        <React.Fragment>
            <ConfirmationDialog
                dialogTitle="工事写真保存"
                bodyText={"工事写真の編集内容を保存します。よろしいですか？"}
                open={windowState}
                onOk={() => {
                    saveConstPhoto(saveData)
                }}
                onCancel={() => { setWindowState(false) }}
                onClose={() => { setWindowState(false) }}
            />

            <Box style={{ paddingRight: "10px" }}>
                <Button variant="contained" color="primary" onClick={() => {
                    let d = buildSaveData(props.data)
                    setSaveData(d)
                    setWindowState(true)
                }}><SaveAltIcon /><MediaPc>保存</MediaPc></Button>
            </Box>
        </React.Fragment>
    )
}

export default FooterControls