import './ConstReport2.css'

import ConstId from './constReport2/ConstId'
import Members from './constReport2/Members'
import SendDate from './constReport2/SendDate'
import Teams from './constReport2/Terms'
import WorkDate from './constReport2/WorkDate'
import MeetingDate from './constReport2/MeetingDate'
import MonthlyTargetVolume from './constReport2/MonthlyTargetVolume'
import MonthlyProgress from './constReport2/MonthlyProgress'
import CumulativeProgress from './constReport2/CumulativeProgress'
import DailyCount from './constReport2/DailyCount'
import TotalCount from './constReport2/TotalCount'
import MainTasksToday from './constReport2/MainTasksToday'
import MorningMeeting from './constReport2/MorningMeeting'
import Worker from './constReport2/Worker'
import MachineOperatingStatus from './constReport2/MachineOperatingStatus'
import LoadingVehicle from './constReport2/LoadingVehicle'
import VisitorInfo from './constReport2/VisitorInfo'
import RecordOfProceed from './constReport2/RecordOfProceed'
import MonthlySafetyDeclaration from './constReport2/MonthlySafetyDeclaration'
import DailySafetyCheck from './constReport2/DailySafetyCheck'
import SafetyInspection from './constReport2/SafetyInspection'
import SiteManagerInspectionFindings from './constReport2/SiteManagerInspectionFindings'
import ProcessMeetingContent from './constReport2/ProcessMeetingContent'
import ConstPhotos from './constReport2/ConstPhotos'

import InfoBox from '../common/InfoBox'
import UserName from '../common/UserName'

import { constListData } from '../hooks/ConstListData'

import React, { useState, useEffect } from 'react'
import { useRecoilValue } from 'recoil'

const ConstReport2 = (props) => {
  const constMap = useRecoilValue(constListData) // 案件一覧データ（マップ構造）
  const [workerHistories, setWorkerHistories] = useState(new Map())
  let constReport = props.value
  let project = constReport.constId ? constMap.get(constReport.constId) : {}

  const handleChangeConstId = (constId) => {
    props.onChangeConstId(constId)
  }

  useEffect(() => {
    if (!constReport || !constReport.constId) {
      return
    }
    let w = new Map()
    constReport.workerHistories.forEach((row) => {
      w.set(row.companyName + "\t" + row.jobType, row.cumulativeProgress)
    })
    setWorkerHistories(w)
  }, [constReport.constId])

  if (!constReport || !constReport.constId) {
    return null
  }

  return (
    <div key={constReport._id} id={constReport._id} className="ConstReport2">
      <div style={{ width: "100%", display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
        <InfoBox title={"氏名"} >
          <UserName userId={constReport.userId} />
        </InfoBox>
      </div>

      <ConstId
        value={constReport.constId}
        readOnly={props.readOnly}
        onChangeConstId={handleChangeConstId}
      />

      <SendDate
        value={constReport.sendDate}
        readOnly={props.readOnly}
      />

      <Teams
        from={project.termFrom}
        to={project.termTo}
        readOnly={props.readOnly}
      />

      <Members
        value={constReport.members}
        readOnly={props.readOnly}
        onChangeValue={(s) => {
          props.onChangeConstReport({ ...constReport, members: s })
        }}
      />

      <MonthlyTargetVolume
        value={constReport.monthlyTargetVolume}
        readOnly={props.readOnly}
        onChangeValue={(s) => {
          props.onChangeConstReport({ ...constReport, monthlyTargetVolume: s })
        }}
      />

      <div className="DateGroup" >
        <WorkDate
          value={constReport.date}
          readOnly={props.readOnly}
        />

        <MeetingDate
          value={constReport.meetingDate}
          meetingTime={constReport.meetingTime.time}
          readOnly={props.readOnly}
          onChangeMeetingDate={(meetingDate) => {
            props.onChangeConstReport({ ...constReport, meetingDate: meetingDate })
          }}
          onChangeMeetingTime={(meetingTime) => {
            props.onChangeConstReport({ ...constReport, meetingTime: { time: meetingTime } })
          }}
        />
      </div>

      <MonthlyProgress
        value={constReport.monthlyProgress}
        readOnly={props.readOnly}
        onChangeValue={(v) => {
          props.onChangeConstReport({ ...constReport, monthlyProgress: v })
        }}
      />

      <CumulativeProgress
        value={constReport.monthlyProgress}
        cumulate={constReport.cumulativeProgress}
        readOnly={true}
      />

      <DailyCount
        value={constReport.dailyCount}
        readOnly={props.readOnly}
        onChangeValue={(v) => {
          props.onChangeConstReport({ ...constReport, dailyCount: v })
        }}
      />

      <TotalCount
        value={constReport.dailyCount}
        cumulate={constReport.totalCount}
        readOnly={true}
      />

      <MainTasksToday
        value={constReport.mainTasksToday}
        readOnly={props.readOnly}
        onChangeValue={(s) => {
          props.onChangeConstReport({ ...constReport, mainTasksToday: s })
        }}
      />

      <MorningMeeting
        from={constReport.morningMeetingStartTime}
        to={constReport.morningMeetingEndTime}
        companyCount={constReport.morningMeetingCompanyCount}
        newEntrants={constReport.morningMeetingNewEntrants}
        mainContent={constReport.morningMeetingMainContent}
        readOnly={props.readOnly}
        onChangeValue={(v) => {
          props.onChangeConstReport({
            ...constReport,
            morningMeetingStartTime: v.from,
            morningMeetingEndTime: v.to,
            morningMeetingCompanyCount: v.companyCount,
            morningMeetingNewEntrants: v.newEntrants,
            morningMeetingMainContent: v.mainContent
          })
        }}
      />

      <Worker
        value={constReport.workers}
        histories={workerHistories}
        readOnly={props.readOnly}
        onChangeWorker={(v) => {
          props.onChangeConstReport({ ...constReport, workers: v })
        }}
      />

      <MachineOperatingStatus
        value={constReport.machineOperatingStatus}
        readOnly={props.readOnly}
        onChangeMachineOperatingStatus={(v) => {
          props.onChangeConstReport({ ...constReport, machineOperatingStatus: v })
        }}
      />

      <LoadingVehicle
        value={constReport.loadingVehicles}
        readOnly={props.readOnly}
        onChangeLoadingVehicle={(v) => {
          props.onChangeConstReport({ ...constReport, loadingVehicles: v })
        }}
      />

      <VisitorInfo
        value={constReport.visitorInfos}
        readOnly={props.readOnly}
        onChangeVisitorInfo={(v) => {
          props.onChangeConstReport({ ...constReport, visitorInfos: v })
        }}
      />

      <RecordOfProceed
        value={constReport.recordOfProceed}
        readOnly={props.readOnly}
        onChangeValue={(s) => {
          props.onChangeConstReport({ ...constReport, recordOfProceed: s })
        }}
      />

      <MonthlySafetyDeclaration
        value={constReport.monthlySafetyDeclaration}
        readOnly={props.readOnly}
        onChangeValue={(s) => {
          props.onChangeConstReport({ ...constReport, monthlySafetyDeclaration: s })
        }}
      />

      <DailySafetyCheck
        value={{ time: constReport.dailySafetyCheckTime.time, inspector: constReport.dailySafetyCheckInspector }}
        readOnly={props.readOnly}
        onChangeValue={(v) => {
          props.onChangeConstReport({ ...constReport, dailySafetyCheckTime: { time: v.time }, dailySafetyCheckInspector: v.inspector })
        }}
      />

      <SafetyInspection
        value={constReport.safetyInspectionItems}
        correctiveAction={constReport.correctiveAction}
        readOnly={props.readOnly}
        onChangeSafetyInspectionItems={(v) => {
          props.onChangeConstReport({ ...constReport, safetyInspectionItems: v })
        }}
        onChangeCorrectiveAction={(v) => {
          props.onChangeConstReport({ ...constReport, correctiveAction: v })
        }}
      />

      <SiteManagerInspectionFindings
        value={constReport.siteManagerInspectionFindings}
        readOnly={props.readOnly}
        onChangeValue={(s) => {
          props.onChangeConstReport({ ...constReport, siteManagerInspectionFindings: s })
        }}
      />

      <ProcessMeetingContent
        value={constReport.processMeetingContent}
        readOnly={props.readOnly}
        onChangeValue={(s) => {
          props.onChangeConstReport({ ...constReport, processMeetingContent: s })
        }}
      />

      <ConstPhotos style={{ minHeight: "100px" }}
        value={constReport.constPhotos}
        readOnly={props.readOnly}
        todayPhotos={constReport.todayPhotos ? constReport.todayPhotos : []}
        onChange={(v) => { props.onChangeConstReport({ ...constReport, constPhotos: v }) }} />
    </div>
  )
}

export default ConstReport2
