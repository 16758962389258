import './SiteManagerInspectionFindings.css'

import LimitedTextarea from '../../common/LimitedTextarea'
import React, { useState } from 'react'

const SiteManagerInspectionFindings = (props) => {
  const [value, setValue] = useState(props.value)
  return (
    <div className="SiteManagerInspectionFindings constVertical" >
      <div className="constTitle">
        <span>現場責任者の巡視所見（巡視時間記載）</span>
      </div>
      <div className="constCell">
        <LimitedTextarea
          maxLines={8}
          maxCharsPerLine={56}
          initialText={value}
          readOnly={props.readOnly}
          style={{ width: "calc(100% - 10px)", margin: "2px" }}
          onChange={(text) => {
            setValue(text)
          }}
          onBlur={(e) => {
            props.onChangeValue(value)
          }}
        />
      </div>
    </div>

  )
}

export default SiteManagerInspectionFindings