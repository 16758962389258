import { userListData } from '../hooks/UserListData'
import { userBufferData } from '../hooks/UserBufferState'

import React, { useState, useEffect } from 'react'
import { useRecoilValue } from 'recoil'

const UserName = (props) => {
    const userBuffer = useRecoilValue(userBufferData)
    const [name, setName] = useState('')

    const userMap = useRecoilValue(userListData) // 社員一覧データ（マップ構造）

    useEffect(() => {
        const userId = props.userId

        const userData = userId ? userMap.get(userId) : null
        let nm = userData ? userData.name : ''

        if (userBuffer && !nm) {
            nm = userBuffer.get(userId)
        }

        setName(nm)
    }, [props.userId, userMap, userBuffer])

    return (
        <React.Fragment>
            {name}
        </React.Fragment>
    )
}

export default UserName