import { http } from "./Config";

const requestPath = "/v1/system";

const getTaxs = () => {
    return http.get(`${requestPath}/tax`)
}

const getTheme = () => {
    return http.get(`${requestPath}/theme`)
}

const exports = {
    getTaxs, getTheme
}

export default exports;
