export const clearPhoto = (state) => {
    let result = { type: "", photos: new Map() }

    return result
}

export const turnPhoto = (state, type, photo) => {
    if (isSelectPhoto(state, type, photo)) {
        return deselectPhoto(state, type, photo)
    } else {
        return selectPhoto(state, type, photo)
    }
}

export const deselectPhoto = (state, type, photo) => {
    let result = { type: type, photos: new Map() }
    if (state.type === result.type) {
        for (let key of state.photos.keys()) {
            result.photos.set(key, state.photos.get(key))
        }
    }

    // 存在する場合
    if (result.photos.has(photo.photoId)) {
        result.photos.delete(photo.photoId)
    }

    return result
}

export const selectPhoto = (state, type, photo) => {
    let result = { type: type, photos: new Map() }
    if (state.type === result.type) {
        for (let key of state.photos.keys()) {
            result.photos.set(key, state.photos.get(key))
        }
    }

    // 存在しない場合
    if (!result.photos.has(photo.photoId)) {
        result.photos.set(photo.photoId, photo)
    }

    return result
}

export const selectPhotos = (state, type, photos) => {
    let result = { type: type, photos: new Map() }
    if (state.type === result.type) {
        for (let key of state.photos.keys()) {
            result.photos.set(key, state.photos.get(key))
        }
    }

    photos.map((photo) => {
        // 存在しない場合
        if (!result.photos.has(photo.photoId)) {
            result.photos.set(photo.photoId, photo)
        }

    })

    return result
}

export const isSelectPhoto = (state, type, photo) => {
    return (state.type === type && state.photos.has(photo.photoId))
}
