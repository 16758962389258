import './Juki.css'

import CellInput from '../../common/CellInput'

import React, { useState, useEffect } from 'react'

const Juki = (props) => {
    const [jukiList, setJukiList] = useState(() => {
        // 初回レンダリング時のみ処理
        return checkRows(props.value) // 表の最下行に空行を追加する
    })

    useEffect(() => {
        setJukiList(checkRows(props.value))
    }, [props.value])

    return (
        <div className="Juki">
            <datalist id="jukiList">
                {
                    props.list.map(v => (<option key={v.name} value={v.name} />))
                }
            </datalist>
            <table className={"dataList"}>
                <thead className={"scrollHead"}>
                    <tr>
                        <th className={"juki"} style={{ textAlign: "center" }}>重機</th>
                        <th className={"count"} style={{ textAlign: "center" }}>数量</th>
                    </tr>
                </thead>
                <thead className={"scrollBody"}>
                    {jukiList.map((row, idx) => {
                        return (
                            <tr key={idx}>
                                <td className={"juki"}>
                                    <CellInput value={row.juki} readOnly={props.readOnly} list="jukiList"
                                        onChange={(event) => {
                                            let v = event.target.value
                                            jukiList[idx].juki = v
                                            setJukiList(checkRows(jukiList))
                                            props.onChangeJukiList(trimRow(jukiList))
                                        }}
                                    />
                                </td>
                                <td className={"count"}>
                                    <CellInput type="number" style={{ textAlign: "right" }} value={row.count} readOnly={props.readOnly}
                                        onChange={(event) => {
                                            let v = Number.parseFloat(event.target.value)
                                            if (Number.isNaN(v)) {
                                                v = ''
                                            }
                                            jukiList[idx].count = v
                                            setJukiList(checkRows(jukiList))
                                            props.onChangeJukiList(trimRow(jukiList))
                                        }}
                                    />
                                </td>
                            </tr>
                        )
                    })}
                </thead>
            </table>
        </div>
    )
}

const isEmpty = (o) => {
    return (o.juki === '' && (!(o.count) || o.count === '' || Number(o.count) === 0))
}

const newData = { juki: '', count: '' }

const trimRow = (values) => {
    return values.slice().filter((row) => { return !isEmpty(row) }).map(v => v)
}
const checkRows = (values) => {
    if (!values) {
        values = []
    }
    values = trimRow(values)
    values.push({ ...newData })
    return values
}


export default Juki
