import './Thumbnail.css'

import { thumbUrl } from '../../services/Config'
import { formatDate } from '../utils/Utils'
import { MediaPc, MediaSmartPhone, isTouchDevice } from './Responsive'

import { turnPhoto, isSelectPhoto } from '../photo/SelectControl'
import { photoSelectState } from '../hooks/PhotoSelectState'
import React, { useState, useRef } from 'react'
import { useRecoilState } from 'recoil'
import { Popper, Paper } from '@mui/material'

const Thumbnail = (props) => {
    const [photoSelect, setPhotoSelect] = useRecoilState(photoSelectState)

    const size = (props.size ? props.size : "s")
    const popperSize = (props.popperSize ? props.popperSize : "l")
    const srcS = thumbUrl + "/s/?photoId=" + props.value.photoId
    const srcM = thumbUrl + "/m/?photoId=" + props.value.photoId
    const srcL = thumbUrl + "/l/?photoId=" + props.value.photoId

    const src = size === "s" ? srcS : size === "m" ? srcM : srcL
    const popperSrc = popperSize === "s" ? srcS : popperSize === "m" ? srcM : srcL

    const [anchorEl, setAnchorEl] = useState(null)
    const ref = useRef(null)

    // PCでのポップアップイメージ表示サイズ
    const IMG_WIDTH_PC = "400px"
    const IMG_HEIGHT_PC = "300px"

    // スマホでのポップアップイメージ表示サイズ
    const IMG_WIDTH_SP = "171px"
    const IMG_HEIGHT_SP = "128px"

    const isSelect = () => {
        if (!props.selectable) {
            return false
        }

        let result = isSelectPhoto(photoSelect, props.type, props.value)
        return result
    }

    let to = null

    const setPop = (event) => {
        let target = event.currentTarget
        to = setTimeout(() => {
            setAnchorEl(target)
        }, 700)
        event.preventDefault()
    }

    const setDirectPop = (event) => {
        let target = event.currentTarget
        setAnchorEl(target)
        event.preventDefault()
    }

    const cancelPop = (event) => {
        if (to) {
            clearTimeout(to)
        }
        if (anchorEl) {
            setAnchorEl(null)
        }
        return true
    }

    const pickupPhoto = (event) => {
        cancelPop(event)

        if (props.selectable) {
            let ps = turnPhoto(photoSelect, props.type, props.value)
            setPhotoSelect(ps)
        }

        if (props.onClickPicture) {
            props.onClickPicture(props.index)
        }
        event.stopPropagation()
    }

    let longTapHandle = null
    const startLongTap = (event) => {
        longTapHandle && clearTimeout(longTapHandle)
        longTapHandle = setTimeout(() => {
            if (to) {
                clearTimeout(to)
            }
            if (anchorEl) {
                setAnchorEl(null)
            }
            if (props.onClickPicture) {
                props.onClickPicture(props.index)
            }
        }, 800)
    }

    const endLongTap = (event) => {
        longTapHandle && clearTimeout(longTapHandle)
    }

    const events = props.cancelPopup ? {
        // ポップアップによる写真詳細が不要な場合
        onClick: pickupPhoto,
    } : isTouchDevice() ? {
        // タッチデバイス対応の場合のイベント設定
        // クリック：ポッパーでの情報表示
        // 長押し：全画面での写真表示
        onClick: setDirectPop,
        onMouseLeave: cancelPop,
        onTouchStart: startLongTap,
        onTouchEnd: endLongTap,
        onContextMenu: setDirectPop,
    } : {
        // タッチデバイス非対応の場合のイベント設定
        // マウスオーバー：ポッパーでの情報表示
        // クリック：全画面での写真表示
        onDrag: cancelPop,
        onMouseEnter: setPop,
        onMouseLeave: cancelPop,
        onDragEnd: cancelPop,
        onMouseDown: cancelPop,
        onClick: pickupPhoto,
    }
    return (
        <React.Fragment>
            <span className="Thumbnail" ref={ref} key={props.key} style={{ margin: "1px", display: "inline-block", position: "relative", webkitUserSelect: "none", webkitTouchCallout: "none" }}
                {...events}
            >
                <img
                    id={"allPhotos-" + props.value.photoId}
                    key={props.value.photoId}
                    src={src}
                    alt=""
                    style={{ minHeight: props.minHeight ? props.minHeight : "60px", maxHeight: props.maxHeight ? props.maxHeight : "60px", width: "auto", border: isSelect() ? "1px solid red" : "1px solid rgba(0,0,0,0)" }}
                    ariaDescribedby={"p" + props.id}
                    width={"80px"}
                    draggable="false"
                    loading={"lazy"}
                />
                <div style={{ display: isSelect() ? "block" : "none", position: "absolute", top: "2px", left: "2px", color: "red", lineHeight: "15px", opacity: 0.5 }}>●</div>

            </span>
            <Popper id={"p" + props.src} style={{ border: "1px solid lightgray", margin: "10px" }} open={Boolean(anchorEl)} anchorEl={ref.current}>
                <Paper style={{ position: "relative", }}>
                    <div style={{ display: "flex", position: "relative", flexDirection: "row", padding: "10px" }}>
                        <div className="PhotoImage">
                            <MediaPc>
                                <img alt="" style={{ display: "block", maxWidth: IMG_WIDTH_PC, maxHeight: IMG_HEIGHT_PC, width: IMG_WIDTH_PC, height: IMG_HEIGHT_PC, objectFit: "contain" }} src={popperSrc} loading={"lazy"} />
                            </MediaPc>
                            <MediaSmartPhone>
                                <img alt="" style={{ display: "block", maxWidth: IMG_WIDTH_SP, maxHeight: IMG_HEIGHT_SP, width: IMG_WIDTH_SP, height: IMG_HEIGHT_SP, objectFit: "contain" }} src={srcS} loading={"lazy"} />
                            </MediaSmartPhone>
                        </div>
                        <div>
                            <table style={{ width: "250px" }}>
                                <tr>
                                    <td>撮影日時</td><td>{formatDate(props.value.day)}</td>
                                </tr>
                                <tr>
                                    <td>工種</td><td>{props.value.constClassName}</td>
                                </tr>
                                <tr>
                                    <td>工種詳細</td><td>{props.value.constTypeName}</td>
                                </tr>
                                <tr>
                                    <td>階数</td><td>{props.value.floor}階</td>
                                </tr>
                                <tr>
                                    <td colspan={2}><pre>{props.value.description}</pre></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </Paper>
            </Popper>
        </React.Fragment>
    )
}

export default Thumbnail