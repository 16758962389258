import JpnDate from '../../common/JpnDate'

import React from 'react'

const WorkDate = (props) => {
  return (
    <div className="WorkDate constRow" >
      <div className="constTitle">
        <span>作業日</span>
      </div>
      <div className="constCell" style={{ paddingLeft: "3px", paddingTop: "5px" }}>
        <JpnDate value={props.value} />
      </div>
    </div>
  )
}

export default WorkDate