import DateTime from '../../common/DateTime'

import React from 'react'

const SendDate = (props) => {
  return (
    <div className="SendDate constRow" >
      <div className="constTitle">
        <span>提出日</span>
      </div>
      <div className="constCell">
        <DateTime value={props.value} />
      </div>
    </div>
  )
}

export default SendDate