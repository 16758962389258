import "./ExpandControl.css"
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
const ExpandControl = (props) => {
  const handleChange = () => {
    props.onChangeState(!props.value)
  }
  return (
    <button style={props.style} className="ExpandControl" onClick={
      handleChange
    }>
      {(props.value) ? (
        <FontAwesomeIcon icon={faChevronDown} />
      ) : (
        <FontAwesomeIcon icon={faChevronRight} />
      )}
    </button>
  )
}

export default ExpandControl