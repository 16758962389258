import { atomFamily, atom } from 'recoil'

export const constPhotoStateFamily = atomFamily({
    key: 'constPhoto',
    default: { window: false, windowState: { x: 0, y: 0, width: 100, height: 100 }, title: null, key: null, name: null, photos: [] }
})

export const constPhotoState = atom({
    key: 'constPhotoState',
    default: { constId: null, allPhotos: { photos: [] }, categories: [], userAllocated: [] },
})

