import CellInput from '../../common/CellInput'

import { cutText } from '../../utils/Utils.js'
import React, { useEffect, useState } from 'react'

const ListItemInput = (props) => {
  const [row, setRow] = useState(props.row)

  useEffect(() => {
    setRow(props.row)
  }, [props.row])

  let name = props.name
  return (
    <CellInput
      className={name + "Input"}
      readOnly={props.readOnly}
      type={props.type}
      line={props.line}
      value={row[name]}
      style={{ textAlign: props.type === "number" ? "right" : "left" }}
      onChange={(e) => {
        let v = e.target.value

        let r = { ...row }
        r[name] = v
        setRow(r)
      }}
      onBlur={(e) => {
        props.onChangeRow(row, props.idx)
      }} />
  )
}

export default ListItemInput
