import './Worker.css'

import { cutText } from '../../utils/Utils'
import { MediaPc, MediaSmartPhone } from '../../common/Responsive'
import ListItemInput from './ListItemInput'
import ListItemNumberInput from './ListItemNumberInput'
import ListItemMultilineInput from './ListItemMultilineInput'

import CellInput from '../../common/CellInput'
import InfoBox from '../../common/InfoBox'

import jaconv from 'jaconv'
import React, { useState, useEffect } from 'react'

const Worker = (props) => {
  const [worker, setWorker] = useState(props.value)

  useEffect(() => {
    setWorker(props.value)
  }, [props.value])

  const handleRow = (row, idx) => {
    let w = worker.concat()

    row.companyName = cutText(jaconv.toZen(row.companyName.normalize('NFKC')), 18)
    row.jobType = cutText(jaconv.toZen(row.jobType.normalize('NFKC')), 10)
    row.contractLevel = cutText(row.contractLevel, 8)
    row.meetingStampField = cutText(row.meetingStampField, 8)

    w[idx] = row
    setWorker(w)
    props.onChangeWorker(w)
  }

  const getCumulativeProgress = (row) => {
    return (row.cumulativeProgress && row.actualPersonnel) ? row.cumulativeProgress + Number(row.actualPersonnel) : row.cumulativeProgress ? row.cumulativeProgress : row.actualPersonnel
  }

  return (
    <div className="Worker">
      <InfoBox title="作業人員" style={{ width: "100%" }} />
      <table className={"dataList"}>
        <MediaPc>
          <thead className={"scrollHead"}>
            <tr>
              <th className="companyName">会社名</th>
              <th className="jobType">職種</th>
              <th className="plannedPersonnel">予定<br />人員</th>
              <th className="actualPersonnel">実績<br />人員</th>
              <th className="cumulativeProgress">累計</th>
              <th className="workDescription">作業内容</th>
              <th className="safetyInstructions">安全指示事項</th>
              <th className="meetingStampField">打合せ者</th>
            </tr>
          </thead>
        </MediaPc>
        <tbody className={"scrollBody"}>
          {worker.map((row, idx) => {
            let key = row.companyName + "\t" + row.jobType
            if (!props.readOnly) {
              row.cumulativeProgress = key ? props.histories.get(key) : 0
            }

            return (
              <tr key={idx} style={{ verticalAlign: "top" }}>
                {/*
                 * このコンポーネントは、PCとスマホでレイアウトが全く異なる為、別でコードを書いている。
                 * 修正時は要注意。
                 */}
                <MediaPc>
                  <td>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <ListItemInput name="companyName" readOnly={props.readOnly} row={row} idx={idx} onChangeRow={handleRow} />
                      <div >
                        <ListItemInput name="contractLevel" type="number" readOnly={props.readOnly} row={row} idx={idx} onChangeRow={handleRow} />
                        <span>次</span>
                      </div>
                    </div>
                  </td>
                  <td >
                    <ListItemInput name="jobType" readOnly={props.readOnly} row={row} idx={idx} onChangeRow={handleRow} />
                  </td>
                  <td >
                    <ListItemNumberInput name="plannedPersonnel" readOnly={props.readOnly} row={row} idx={idx} onChangeRow={handleRow} />
                  </td>
                  <td >
                    <ListItemNumberInput name="actualPersonnel" readOnly={props.readOnly} row={row} idx={idx} onChangeRow={handleRow} />
                  </td>
                  <td >
                    <div style={{ position: "relative", paddingTop: "1px" }}>
                      <CellInput className="cumulativeProgressInput" type="number" readOnly={true} value={getCumulativeProgress(row)} />
                    </div>
                  </td>
                  <td >
                    <ListItemMultilineInput name="workDescription" readOnly={props.readOnly} row={row} idx={idx} onChangeRow={handleRow} />
                  </td>
                  <td >
                    <ListItemMultilineInput name="safetyInstructions" readOnly={props.readOnly} row={row} idx={idx} onChangeRow={handleRow} />
                  </td>
                  <td className="meetingStampField">
                    <div style={{ position: "absolute", inset: 0, display: "flex", alignItems: "center" }}>
                      <ListItemInput name="meetingStampField" readOnly={props.readOnly} row={row} idx={idx} onChangeRow={handleRow} />
                    </div>
                  </td>
                </MediaPc>
                <MediaSmartPhone>
                  <td style={{ width: "auto" }}>
                    <div style={{ display: "flex" }}>
                      <span>社名</span>
                      <ListItemInput name="companyName" readOnly={props.readOnly} row={row} idx={idx} onChangeRow={handleRow} />
                      <ListItemInput name="contractLevel" type="number" readOnly={props.readOnly} row={row} idx={idx} onChangeRow={handleRow} />
                      <span>次&nbsp;職種</span>
                      <ListItemInput name="jobType" readOnly={props.readOnly} row={row} idx={idx} onChangeRow={handleRow} />
                    </div>
                    <div style={{ display: "flex" }}>
                      <span >予定人員</span>
                      <ListItemNumberInput name="plannedPersonnel" readOnly={props.readOnly} row={row} idx={idx} onChangeRow={handleRow} />
                      <span>実働人員</span>
                      <ListItemNumberInput name="actualPersonnel" readOnly={props.readOnly} row={row} idx={idx} onChangeRow={handleRow} />
                      <span>累計</span>
                      <div style={{ position: "relative", paddingTop: "1px" }}>
                        <CellInput className="cumulativeProgressInput" type="number" readOnly={true} value={getCumulativeProgress(row)} />
                      </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div>
                        <div >
                          <span >作業内容</span>
                        </div>
                        <div >
                          <ListItemMultilineInput name="workDescription" readOnly={props.readOnly} row={row} idx={idx} onChangeRow={handleRow} />
                        </div>
                      </div>
                      <div>
                        <div >
                          <span >安全指示事項</span>
                        </div>
                        <div >
                          <ListItemMultilineInput name="safetyInstructions" readOnly={props.readOnly} row={row} idx={idx} onChangeRow={handleRow} />
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="meetingStampField">
                    <div style={{ position: "absolute", inset: 0, display: "flex", alignItems: "center" }}>
                      <ListItemInput name="meetingStampField" readOnly={props.readOnly} row={row} idx={idx} onChangeRow={handleRow} />
                    </div>
                  </td>
                </MediaSmartPhone>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default Worker
