import 'react-calendar/dist/Calendar.css'
import './DateInputDialog.css'

import { MediaPc, MediaSmartPhone } from './Responsive'

import InfoBox from '../common/InfoBox'
import { getToday, convIsoDate } from '../utils/Utils'
import MeService from '../../services/MeService'
import { errorState } from '../hooks/ErrorState'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import ReactCalendar from 'react-calendar'
import { Button } from '@mui/material'
import styled from '@emotion/styled'
import { Transition } from 'react-transition-group'

import React, { useState, useEffect } from 'react'
import { useSetRecoilState } from 'recoil'

const DateInputDialog = (props) => {
    return (
        <React.Fragment>
            <MediaPc>
                <DateInputDialogForPc {...props} />
            </MediaPc>
            <MediaSmartPhone>
                <DateInputDialogForSmartPhone {...props} />
            </MediaSmartPhone>
        </React.Fragment>
    )
}

const ReportCalendar = styled(ReactCalendar)`
    button {
        padding-top: 8px;
    }
`
const DateInputDialogForPc = (props) => {
    const setError = useSetRecoilState(errorState)
    const [date, setDate] = useState({ today: null, next: null })

    const getNextDate = (d) => {
        let dt = convIsoDate(d)
        MeService.nextDate(dt)
            .then(
                response => {
                    setDate({ today: d, next: new Date(response.data) })
                },
                error => {
                    setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            ).then(
        ).catch(error => {
            setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
        })
    }

    useEffect(() => {
        getNextDate(getToday())
    }, [])

    return (
        <Dialog
            className="DateInputDialog"
            disableEscapeKeyDown={!props.canCancel}
            open={props.open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick' && props.onClose) {
                    props.onClose()
                }
            }}
            ariaLabelledby="form-dialog-title" >
            <DialogTitle id="form-dialog-title">日付を入力</DialogTitle>
            <DialogContent>
                <div style={{
                    display: "flex", position: "relative", overflow: "hidden"
                }}>
                    <div style={{ display: "block", positoin: "relative", width: "270px", height: "330px" }}>
                        <InfoBox style={{ position: "relative", width: "270px" }} title="当日" value={date.today}></InfoBox>
                        <ReportCalendar
                            calendarType="gregory"
                            formatDay={(locale, date) => { return '' + date.getDate() }}
                            onChange={(v) => {
                                getNextDate(v)
                            }}
                            value={date.today}
                            view="month"
                        />
                    </div>
                    <div style={{ width: "5px" }} />
                    <div style={{ display: "block", positoin: "relative", width: "270px", height: "330px" }}>
                        <InfoBox style={{ position: "relative", width: "270px" }} title="次の日" value={date.next}></InfoBox>
                        <ReportCalendar
                            calendarType="gregory"
                            formatDay={(locale, date) => { return '' + date.getDate() }}
                            onChange={(v) => {
                                setDate({ ...date, next: v })
                            }}
                            value={date.next}
                            view="month"
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                {(props.canCancel) ? (
                    <Button
                        variant="contained"
                        onClick={() => {
                            if (props.onClose) {
                                props.onClose()
                            }
                        }} >
                        キャンセル
                    </Button>
                ) : (
                    <React.Fragment />
                )}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        if (props.onOk) {
                            props.onOk(date)
                        }
                    }}
                >
                    作成を開始
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const DateInputDialogForSmartPhone = (props) => {
    const setError = useSetRecoilState(errorState)
    const [inputStep, setInputStep] = useState(0)
    const [date, setDate] = useState({ today: null, next: null })
    const calendarSlider = {
        entering: { left: '0px', transition: 'all 300ms ease' },
        entered: { left: '0px' },
        exiting: { left: '-100%', transition: 'all 300ms ease' },
        exited: { left: '-100%' },
    }

    useEffect(() => {
        setInputStep(0)
    }, [props.open])
    const getNextDate = (d) => {
        let dt = convIsoDate(d)
        MeService.nextDate(dt)
            .then(
                response => {
                    setDate({ today: d, next: new Date(response.data) })
                },
                error => {
                    setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            ).then(
        ).catch(error => {
            setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
        })
    }

    useEffect(() => {
        getNextDate(getToday())
    }, [])

    return (
        <Dialog
            className="DateInputDialog"
            disableEscapeKeyDown={!props.canCancel}
            open={props.open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick' && props.onClose) {
                    props.onClose()
                }
            }}
            ariaLabelledby="form-dialog-title" >
            <DialogTitle id="form-dialog-title">{inputStep === 0 ? "当日" : "次の日"}の日付を入力</DialogTitle>
            <DialogContent>
                <div style={{
                    position: "relative", overflow: "hidden",
                }}>
                    <Transition in={inputStep === 0} timeout={1500}>
                        {(state) => (
                            <div style={{ display: "flex", position: "relative", ...calendarSlider[state] }}>
                                <div style={{ display: "block", position: "relative", width: "100%", height: "330px" }}>
                                    <InfoBox style={{ position: "relative", width: "270px" }} title="当日" value={date.today}></InfoBox>
                                    <ReportCalendar
                                        calendarType="gregory"
                                        formatDay={(locale, date) => { return '' + date.getDate() }}
                                        onChange={(v) => {
                                            getNextDate(v)
                                        }}
                                        value={date.today}
                                    />
                                </div>
                                <div style={{ display: "block", position: "relative", width: "100%", height: "330px" }}>
                                    <InfoBox style={{ position: "relative", width: "270px" }} title="次の日" value={date.next}></InfoBox>
                                    <ReportCalendar
                                        calendarType="gregory"
                                        formatDay={(locale, date) => { return '' + date.getDate() }}
                                        onChange={(v) => {
                                            setDate({ ...date, next: v })
                                        }}
                                        value={date.next}
                                    />
                                </div>
                            </div>
                        )}
                    </Transition>
                </div>
            </DialogContent>
            <DialogActions>
                {(props.canCancel) ? (
                    <Button
                        variant="contained"
                        onClick={() => {
                            if (props.onClose) {
                                props.onClose()
                            }
                        }} >
                        キャンセル
                    </Button>
                ) : (
                    <React.Fragment />
                )}
                {inputStep === 1 ? (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            setInputStep(0)
                        }}
                    >
                        ＜　戻る
                    </Button>
                ) : (
                    <React.Fragment />
                )}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        if (inputStep === 0) {
                            setInputStep(1)
                        } else {
                            if (props.onOk) {
                                props.onOk(date)
                            }
                        }
                    }}
                >
                    {inputStep === 0 ? "次へ　＞" : "作成を開始"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DateInputDialog