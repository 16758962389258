import { http } from "./Config"

const requestPath = "/v1/users/user"
const requestGroupPath = "/v1/users/group"
const requestUserBufferPath = "/v1/users/userBuffer"

const findAll = (query, paging, fields) => {
  let params = [];

  if (fields !== null && fields.length) {
    params.push("fields=" + fields.join(','))
  }
  if (paging) {
    if (paging.limit) {
      params.push(`limit=${paging.limit}`);
      const offset = paging.limit * (paging.page - 1)
      params.push(`offset=${offset}`)
    }

    paging.sort.forEach((s) => {
      params.push(`sort=${s.field},${s.direction}`)
    });
  }
  if (query) {
    params.push("query=" + encodeURIComponent(query))
  }

  return http.get(`${requestPath}/${params.length ? "?" + params.join("&") : ""}`)
}

const findGroupAll = (query, paging, fields) => {
  let params = [];

  if (fields !== null && fields.length) {
    params.push("fields=" + fields.join(','))
  }
  if (paging) {
    if (paging.limit) {
      params.push(`limit=${paging.limit}`);
      const offset = paging.limit * (paging.page - 1)
      params.push(`offset=${offset}`)
    }

    paging.sort.forEach((s) => {
      params.push(`sort=${s.field},${s.direction}`)
    });
  }
  if (query) {
    params.push("query=" + encodeURIComponent(query))
  }

  return http.get(`${requestGroupPath}/${params.length ? "?" + params.join("&") : ""}`)
}

const findUserBuffer = () => {
  return http.get(`${requestUserBufferPath}`)
}

const exports = {
  findAll, findGroupAll, findUserBuffer,
}

export default exports
