import CellInput from '../common/CellInput'
import ListItemLink from '../common/ListItemLink'
import ConfirmationDialog from '../common/ConfirmationDialog'

import { useAutoFocus } from '../hooks/AutoFocus'
import { constPhotoStateFamily } from '../hooks/ConstPhotoState'

import React, { useState, useEffect } from 'react'
import { useRecoilState } from 'recoil'

import { ListItemText, ListItemIcon, } from '@mui/material'

import MenuBookIcon from '@mui/icons-material/MenuBook'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'

const BookListItem = (props) => {
    const [open, setOpen] = useState(false)
    const atom = constPhotoStateFamily(props.data.key)
    const [state, setState] = useRecoilState(atom)
    const [edit, setEdit] = useState(false)

    useEffect(() => {
        let data = props.data
        setState(data)
        if (edit != !data.title) {
            setEdit(!data.title)
        }
    }, [props.data])

    const ref = useAutoFocus()

    const completeInputName = (value) => {
        let constCategory = state.constCategory
        setState({ ...state, constCategory: constCategory, name: value, title: value })
        setEdit(false)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <ListItemLink className="ListItemLink"
            onClick={() => {
                setState({ ...state, window: !!state.title })
                props.onMostFront(state.name)
            }}
            style={{ backgroundColor: state?.window ? "#ccc" : "transparent" }}
        >
            <ListItemIcon>
                <MenuBookIcon />
            </ListItemIcon>
            {
                (!edit) ? (
                    <div>
                        <ConfirmationDialog dialogTitle="削除確認"
                            bodyText={"［" + state.title + "］を削除します。よろしいですか？"}
                            open={open}
                            onOk={() => {
                                props.onRemove()
                                handleClose()
                            }}
                            onCancel={handleClose}
                            onClose={handleClose}
                        >
                        </ConfirmationDialog>
                        <ListItemText primary={state.title} />
                        {(props.editable) ?
                            <RenameControl
                                style={{ position: "absolute", top: "10px", right: "30px" }}
                                onRenameItem={(e) => {
                                    setEdit(true)
                                }}
                            >
                            </RenameControl>
                            : <React.Fragment></React.Fragment>}
                        {(props.removable) ?
                            <RemoveControl
                                style={{ position: "absolute", top: "10px", right: "10px" }}
                                onRemoveItem={(e) => {
                                    setOpen(true)
                                }}
                            >
                            </RemoveControl>
                            : <React.Fragment></React.Fragment>}
                    </div>
                ) : (
                    <CellInput
                        inputRef={ref}
                        defaultValue={state.name}
                        onBlur={(e) => {
                            if (!e.target.value) {
                                e.target.focus()
                            } else {
                                completeInputName(e.target.value)
                            }
                        }}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                if (!e.target.value) {
                                    e.target.focus()
                                } else {
                                    completeInputName(e.target.value)
                                }
                                return false
                            }
                        }}
                        onClick={(e) => {
                            e.stopPropagation()
                        }} />
                )
            }

        </ListItemLink>
    )
}

const RemoveControl = (props) => {
    return (
        <div className="BookListEditControl" style={props.style}>
            <CloseIcon className="RemoveIcon" onClick={(e) => {
                props.onRemoveItem(e)
                e.stopPropagation()
            }} />
        </div>
    )
}

const RenameControl = (props) => {
    return (
        <div className="BookListEditControl" style={props.style}>
            <EditIcon className="RenameIcon" onClick={(e) => {
                props.onRenameItem(e)
                e.stopPropagation()
            }} />
        </div>
    )
}

export default BookListItem