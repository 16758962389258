import './ListItemNumberInput.css'

import LimitedTextarea from '../../common/LimitedTextarea'

import React, { useEffect, useState } from 'react'

const ListItemMultilineInput = (props) => {
  const [row, setRow] = useState(props.row)
  useEffect(() => {
    setRow(props.row)
  }, [props.row])
  let name = props.name
  return (
    <LimitedTextarea
      className={name + "Input ListItemMultilineInput"}
      maxLines={2}
      maxCharsPerLine={30}
      initialText={row[name]}
      readOnly={props.readOnly}
      onChange={(text) => {
        let r = { ...row }
        r[name] = text
        setRow(r)
      }}
      onBlur={(e) => {
        props.onChangeRow(row, props.idx, props.normalize)
      }} />
  )
}

export default ListItemMultilineInput
