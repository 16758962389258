import { baseUrl } from './Config'

const login = () => {
  // SAMLではAjaxは使用できないためリダイレクトさせる必要がある
  // RelayStateパラメータ：ログイン後のリダイレクト先（必須）。クライアント側の現在のURLにしておく
  window.location.href = baseUrl + `/login?RelayState=` + encodeURIComponent(window.location.href)
}

const logout = () => {
  // SAMLではAjaxは使用できないためリダイレクトさせる必要がある
  // RelayStateパラメータ：ログアウト後のリダイレクト先（必須）。クライアント側の現在のURLにしておく。
  window.location.href = baseUrl + `/logout?RelayState=` + encodeURIComponent(window.location.href)
}

const exports = {
  login,
  logout,
}

export default exports