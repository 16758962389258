import './Tasks.css'
import Header from './common/Header'
import Footer from './common/Footer'
import Content from './common/Content'
import MainContent from './common/MainContent'
import { formatDate } from './utils/Utils'
import { ROLES } from './utils/Utils'
import InfoBox from './common/InfoBox'
import { RedButton, GreenButton, BlueButton } from './common/ColorButton'
import { MediaSmartPhone, MediaPc } from './common/Responsive'

import TaskService from '../services/TaskService'
import MeService from '../services/MeService'

import { sessionUserState } from './hooks/SessionUserState'
import { errorState } from './hooks/ErrorState'

import React, { useState, useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useNavigate } from 'react-router-dom'

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material'
import DescriptionIcon from '@mui/icons-material/Description'
import TimerIcon from '@mui/icons-material/Timer'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import CommentIcon from '@mui/icons-material/Comment'

const Tasks = (props) => {
    const navigate = useNavigate()

    const [taskData, setTaskData] = useState([])
    const setError = useSetRecoilState(errorState) // エラーメッセージ表示用

    const jumpToPage = (link) => {
        if (link && (link.startsWith("http://") || link.startsWith("https://"))) {
            window.location.href = link
        } else {
            navigate(link)
        }
    }

    const searchData = () => {
        Promise.all([
            TaskService.load(),
        ])
            .then(
                response => {
                    // state更新
                    setTaskData(response[0].data.objects)
                },
                error => {
                    setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            ).catch(error => {
                setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
            })
    }

    useEffect(() => {
        searchData()
    }, []);


    const levelColors = {
        0: "blue",  // Info
        1: "darkyellow",    // Warn
        2: "red"    // Error

    }
    return (
        <Content>
            <Header title="工事業務管理システム" />
            <MainContent className="Tasks">
                <ShortcutMenu />
                <div style={{ height: "calc(100% - 95px)" }}>
                    <TableContainer component={Paper} style={{ display: "block", height: "100%", minHeight: "100%" }}>
                        <InfoBox title="やらなければいけない事" style={{ width: "100%" }}></InfoBox>
                        <Table stickyHeader size="small" ariaLabel="a dense table">
                            <TableHead>
                                <TableRow>
                                    <MediaPc>
                                        <TableCell align="center" width="60px">処理</TableCell>
                                    </MediaPc>
                                    <TableCell align="center" width="80px">日付</TableCell>
                                    <TableCell align="center" width="600px">情報</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {taskData.map((row) => {
                                    return (
                                        <TableRow key={row.id} >
                                            <MediaPc>
                                                <TableCell align="center"  >
                                                    <Button
                                                        variant="contained"
                                                        color="inherit"
                                                        onClick={() => {
                                                            jumpToPage(row.link)
                                                        }}
                                                    >処理</Button>
                                                </TableCell>
                                                <TableCell align="center">{formatDate(row.date)}</TableCell>
                                                <TableCell ><span style={{ color: levelColors[row.level] }}>{row.message}</span></TableCell>
                                            </MediaPc>
                                            <MediaSmartPhone>
                                                <TableCell onClick={() => {
                                                    jumpToPage(row.link)
                                                }} align="center">
                                                    {formatDate(row.date)}
                                                </TableCell>
                                                <TableCell onClick={() => {
                                                    jumpToPage(row.link)
                                                }}>
                                                    <span style={{ color: levelColors[row.level] }}>{row.message}</span>
                                                </TableCell>
                                            </MediaSmartPhone>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </MainContent>
            <Footer />
        </Content>
    )
}

const ShortcutMenu = (props) => {
    const navigate = useNavigate()

    const [tsunagUrl, setTsunagUrl] = useState("")
    const userData = useRecoilValue(sessionUserState)
    const setError = useSetRecoilState(errorState) // エラーメッセージ表示用

    const roles = userData.roles

    useEffect(() => {
        Promise.all([
            MeService.getTsunAGUrl(),
        ])
            .then(
                response => {
                    // state更新
                    setTsunagUrl(response[0].data.url)
                },
                error => {
                    setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            ).catch(error => {
                setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
            })
    }, []);
    return (
        <Paper style={{ display: "flex", marginBottom: "5px" }}>
            <RedButton
                style={{ margin: "10px", padding: "15px" }}
                variant="contained"
                size="large"
                onClick={() => {
                    navigate("/report")
                }}
            ><DescriptionIcon /><p className="ButtonLabel">日報作成</p></RedButton>
            <BlueButton
                style={{ margin: "10px", padding: "15px" }}
                variant="contained"
                size="large"
                onClick={() => {
                    window.location = tsunagUrl
                }}
            ><TimerIcon /><p className="ButtonLabel">勤怠入力</p></BlueButton>
            {roles.includes(ROLES.ADMINISTRATOR) ? (
                <GreenButton
                    style={{ margin: "10px", padding: "15px" }}
                    variant="contained"
                    size="large"
                    onClick={() => {
                        navigate("/workapprove")
                    }}
                ><ThumbUpIcon /><p className="ButtonLabel">業務日報承認</p></GreenButton>
            ) : (
                <React.Fragment />
            )}
            {roles.includes(ROLES.ADMINISTRATOR) ? (
                <GreenButton
                    style={{ margin: "10px", padding: "15px" }}
                    variant="contained"
                    size="large"
                    onClick={() => {
                        navigate("/constdiscus")
                    }}
                ><CommentIcon /><p className="ButtonLabel">工事日報確認</p></GreenButton>
            ) : (
                <React.Fragment />
            )}

        </Paper>
    )
}

export default Tasks
