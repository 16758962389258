import { MediaPc, MediaSmartPhone } from '../common/Responsive'
import UserName from '../common/UserName'
import { sessionUserState } from '../hooks/SessionUserState'

import React, { useState } from 'react'
import { useRecoilValue } from 'recoil'
import { Popper, Paper } from '@mui/material'
import { useEffect } from 'react'

/* 既読の表示処理 */
const AlreadyReads = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)
  useEffect(() => {
    let app = document.getElementById('App')
    app.addEventListener('mousedown', () => {
      setAnchorEl(null)
    })
    return () => {
      app.removeEventListener('mousedown', () => {
        setAnchorEl(null)
      })
    }
  }, [])

  return (
    props.value ?
      <React.Fragment>
        <MediaPc>
          <div style={{ position: "relative", fontSize: "8px", display: "block", cursor: "pointer" }}
            onMouseOver={(event) => {
              setAnchorEl(event.currentTarget)
            }}
            onMouseLeave={(event) => {
              setAnchorEl(null)
            }}
            onClick={(event) => {
              setAnchorEl(anchorEl ? null : event.currentTarget)
            }}>
            <ReadCheck anchorEl={anchorEl} value={props.value} otherComment={props.otherComment} />
          </div>
        </MediaPc>
        <MediaSmartPhone>
          <div style={{ position: "relative", fontSize: "8px", display: "block", cursor: "pointer" }}
            onClick={(event) => {
              setAnchorEl(anchorEl ? null : event.currentTarget)
            }}>
            <ReadCheck anchorEl={anchorEl} value={props.value} otherComment={props.otherComment} />
          </div>
        </MediaSmartPhone>
      </React.Fragment>
      : null
  )
}

const ReadCheck = (props) => {
  const userData = useRecoilValue(sessionUserState)

  const open = Boolean(props.anchorEl)

  return (
    <React.Fragment>
      既読 <div style={{ display: "inline", textDecoration: "underline" }} >
        {props.value.filter((rc) => { return rc.check && rc.userId !== userData.userId }).length}
      </div>
      {props.value.filter((rc) => { return rc.check && rc.userId !== userData.userId }).length > 0 ? (
        <Popper open={open} anchorEl={props.anchorEl} placement={props.otherComment ? "right-start" : "left-start"} transition={false} style={{ zIndex: 10 }}>
          <Paper style={{ padding: "5px", backgroundColor: "lightgreen", fontSize: "12px" }}>
            {props.value.filter((rc) => { return rc.check && rc.userId !== userData.userId }).map((rc) => {
              return (<div style={{ whiteSpace: "nowrap" }}><UserName userId={rc.userId} /></div>)
            })}
          </Paper>
        </Popper>
      ) : null}
    </React.Fragment>
  )
}

export default AlreadyReads