import { atom } from 'recoil'

export const sessionUserState = atom({
  key: 'sessionUser',
  default: {
    email: "",
    firstName: "",
    groupId: "",
    groupName: "",
    lastName: "",
    name: "",
    roles: [],
    userId: "",
    userName: "",
    superiorId: "",
    superiorName: "",
  },
})

