/*
 * 工期開始日〜終了日までの月の配列を作成する為の関数群
 */

const calcMonthRange = (quote) => {
  if (!quote || !quote.termFrom || !quote.termTo)
    return []
  const start = addMonth(getYM(quote.termFrom), -3)
  const end = addMonth(getYM(quote.termTo), 1)
  return getYMRangeArray(start, end)
}

const getInitialVols = (quote) => {
  let ymRange = calcMonthRange(quote) // この工事が行われる月の範囲を取得する
  let initialVols = new Map()
  ymRange.forEach((ym) => {
    initialVols.set(ym.year + "-" + ym.month, { vol: 0, price: 0 })
  })
  return initialVols
}

// 開始年月〜終了年月までの月の配列を作成する
const getYMRangeArray = (start, end) => {
  console.log(start, end)
  let n = { year: start.year, month: start.month }
  let array = []

  while ((n.year * 100 + n.month) <= (end.year * 100 + end.month)) {
    array.push({ year: n.year, month: n.month })
    n = addMonth(n, 1)
  }

  return array
}

// 年月に月数を加算する
const addMonth = (ym, n) => {
  if (ym.year === null || ym.month === null) {
    return null
  }

  const months = (ym.year * 12 + ym.month - 1) + n  // 一旦、月数のみにする
  const newYear = Math.floor(months / 12) // 12で割って年を求める
  const newMonth = months % 12 + 1  // 12の余り＋１で月を求める

  return { year: newYear, month: newMonth }
}

// 日付から年と月を取得する
const getYM = (dt) => {
  if (!dt) {
    return { year: null, month: null }
  }
  if (typeof (dt) === 'string') {
    dt = new Date(dt)
  }

  return { year: dt.getFullYear(), month: dt.getMonth() + 1 }
}

const getDaysInMonthWithinRange = (start, end, targetYear, targetMonth) => {
  // 対象年月の初日と最終日を設定
  const targetStart = new Date(targetYear, targetMonth - 1, 1)
  const targetEnd = new Date(targetYear, targetMonth, 0)

  // 範囲の開始日を対象月の開始日と比較して、遅い方を選択
  const rangeStart = new Date(Math.max(start, targetStart))

  // 範囲の終了日を対象月の終了日と比較して、早い方を選択
  const rangeEnd = new Date(Math.min(end, targetEnd))

  // 日数を計算（終了日 - 開始日 + 1）
  const days = Math.max(0, Math.floor((rangeEnd - rangeStart) / (1000 * 60 * 60 * 24)) + 1)

  return days
}

export { calcMonthRange, getInitialVols, getYMRangeArray, addMonth, getYM, getDaysInMonthWithinRange, }