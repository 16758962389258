import { http } from "./Config";

const requestPath = "/v1/camera";

export const getPhotos = (query) => {
    query = encodeURIComponent(query)
    return http.get(`${requestPath}?query=${query}`)
}

const exports = {
    getPhotos,
}

export default exports;
