import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

const ConfirmationDialog = (props) => {
  return (
    <div style={{ display: "inline", ...props.style }}>
      {/* <Button variant="contained" size="small" color={props.buttonColor} onClick={props.open}>{props.buttonText}</Button> */}
      <Dialog
        open={props.open}
        onClose={(event) => {
          if (props.onClose) {
            props.onClose(event)
          }
        }}
      >
        <DialogTitle style={{ padding: "3px" }} className={"label"}>{props.dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: "black" }}>
            {props.bodyText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" size="small"
            onClick={() => {
              if (props.onCancel) {
                props.onCancel()
              }
            }} color="inherit">
            キャンセル
          </Button>
          <Button variant="contained" size="small"
            onClick={() => {
              if (props.onOk) {
                props.onOk()
              }
            }} color="primary" autoFocus>
            ＯＫ
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  )
}

export default ConfirmationDialog