import CellInput from '../../common/CellInput'

import React from 'react'

const TotalCount = (props) => {
  const v = Number(props.value)
  const c = Number(props.cumulate)
  const value = (!isNaN(v) ? v : 0) + (!isNaN(c) ? c : 0)
  return (
    <div className="TotalCount constRow" >
      <div className="constTitle">
        <span>累計</span>
      </div>
      <div className="constCell">
        <CellInput type="number"
          value={value}
          readOnly={true}
          style={{ width: "40px", textAlign: "right" }}
        />
      </div>
    </div>

  )
}

export default TotalCount