import { thumbUrl } from '../../services/Config'
import { photoSelectState } from '../hooks/PhotoSelectState'

import React, { useEffect, useRef } from 'react'
import { useRecoilValue } from 'recoil'

const DragItem = (props) => {
    const threshold = 5
    const selectPhotos = useRecoilValue(photoSelectState)

    let dragState = { mouseEvent: false, drag: false, startx: 0, starty: 0, }
    const photos = []
    for (let key of selectPhotos.photos.keys()) {
        photos.push(thumbUrl + "/s/?photoId=" + selectPhotos.photos.get(key).photoId)
    }

    const div = useRef()

    const handleDrag = (event) => {
        if (dragState.mouseEvent === true && dragState.drag === false) {
            if (Math.abs(dragState.startx - event.pageX) > threshold || Math.abs(dragState.starty - event.pageY) > threshold) {
                dragState = { ...dragState, drag: true }
            }
        }
        event.stopPropagation()
        return false
    }

    const handleDragStart = (event) => {
        props.connector.isDrag = true
        if (props.onDragPhoto) {
            props.onDragPhoto(dragState)
        }
        event.dataTransfer.setDragImage(document.getElementById("draggablePhotos"), 0, 0)
        dragState = { mouseEvent: true, drag: false, startx: event.pageX, starty: event.pageY }
        event.stopPropagation()
        return false
    }

    const handleDragEnd = (event) => {
        props.connector.isDrag = false
        if (dragState.mouseEvent) {
            dragState = { mouseEvent: false, drag: false, startx: 0, starty: 0 }
        }
        event.stopPropagation()
        return false
    }

    useEffect(() => {
        div.current.addEventListener('drag', handleDrag, true)
        div.current.addEventListener('dragstart', handleDragStart, true)
        div.current.addEventListener('dragend', handleDragEnd, true)
        props.connector.isDrag = false
    }, [])

    return (
        <div
            ref={div}
            style={{ position: "relative", cursor: "pointer" }}
            draggable={true}
            onMouseDown={(event) => {
                event.stopPropagation()
            }}
        // onTouchStart={(event) => {
        //     event.stopPropagation()
        // }}
        >
            {props.children}
        </div>
    )
}

export default DragItem
