import './ProgressStatus.css'

import CellInput from '../../common/CellInput'
import React, { useState, useEffect } from 'react'
import { Select, MenuItem } from '@mui/material'

const ProgressStatus = (props) => {
    const [selectVal, setSelectVal] = useState(!props.value || props.value === 0 ? 0 : (props.value < 0 ? -1 : 1))
    const [val, setVal] = useState(Math.abs(props.value))

    useEffect(() => {
        setSelectVal((!props.value || props.value === 0 ? 0 : (props.value < 0 ? -1 : 1)))
        setVal(Math.abs(props.value))
    }, [props.value])

    useEffect(() => {
        props.onChangeProgress(selectVal * val)
    }, [selectVal, val])

    return (
        <div className="ProgressStatus">
            {selectVal !== 0 ? (
                <span style={{ display: "flex", alignItems: "bottom" }}>
                    <CellInput value={val}
                        style={{ width: "50px", textAlign: "right" }}
                        type="number"
                        readOnly={props.readOnly}
                        onChange={(event) => {
                            setVal(event.target.value)
                        }} />
                    <div style={{ lineHeight: "26px", marginRight: "10px" }}>日</div>
                </span>
            ) : (
                <React.Fragment />
            )}
            <Select className="ProgressSelector" style={{ fontSize: "13px" }} size="small" value={selectVal} readOnly={props.readOnly}
                onChange={(event) => {
                    if (event.target.value !== 0 && val === 0) {
                        setVal(1)
                    }
                    setSelectVal(event.target.value)
                }}
            >
                <MenuItem value={0}>スケジュール通り</MenuItem>
                <MenuItem value={-1}>前倒し</MenuItem>
                <MenuItem value={1}>遅れ</MenuItem>
            </Select>
        </div>
    )
}

export default ProgressStatus