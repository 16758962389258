import BookListItem from './BookListItem'
import { RedIconButton } from '../common/ColorButton'
import { constPhotoState } from '../hooks/ConstPhotoState'

import React from 'react'
import { useRecoilState } from 'recoil'
import { List } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

const BookList = (props) => {
    const [data, setData] = useRecoilState(constPhotoState)
    const buildUserCategory = () => {
        let S = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
        let N = 8
        let s = Array.from(crypto.getRandomValues(new Uint8Array(N))).map((n) => S[n % S.length]).join('')

        return "user-" + s  // user-８文字のランダム文字列をキーとする
    }

    const handleMostFront = (name) => {
        const fn = props.connector[name].moveFront
        fn()
    }

    return (
        <div className="BookList" {...props}>
            <List>
                <BookListItem key="allPhotos" constId={data.constId} data={data.allPhotos}
                    onMostFront={(name) => {
                        handleMostFront("allPhotos")
                    }}
                />
                {
                    data.categories?.map(category => {
                        return (
                            <BookListItem key={category.constCategory} data={category}
                                onMostFront={(name) => {
                                    handleMostFront("category-" + category.constCategory)
                                }}
                            />
                        )

                    })
                }
                {
                    data.userAllocated?.map(user => {
                        return (
                            <BookListItem key={user.constCategory} data={user}
                                removable={true}
                                editable={true}
                                onRemove={() => {
                                    let userAllocated = data.userAllocated.filter((u) => { return u.key !== user.key })
                                    setData({ ...data, userAllocated: userAllocated })
                                }}
                                onMostFront={(name) => {
                                    handleMostFront(user.constCategory)
                                }}
                            />
                        )

                    })
                }
            </List>
            <RedIconButton size="small" style={{ display: "block" }} ariaLabel="edit" color="primary"
                onClick={() => {
                    let userAllocated = data.userAllocated.slice()
                    let key = buildUserCategory()
                    userAllocated.push({
                        key: key,
                        constCategory: key,
                        name: null,
                        title: null,
                        window: false,
                        photos: [],
                        rename: true,
                        deletable: true,
                        date: new Date(),
                        printConstClass: true,
                        printConstType: true,
                        printDate: true,
                        printDescription: true,
                        printDetail: true,
                        printFloor: true,
                        printQuolity: "l",
                        startPage: 1,
                        userId: ''
                    })
                    setData({ ...data, userAllocated: userAllocated })
                }}
            >
                <AddIcon />
            </RedIconButton>
        </div>
    )
}

export default BookList