import './TimeInput.css'
import React, { useState, useRef, useEffect } from 'react'

const TimeInput = (props) => {
  const [value, setValue] = useState(props.value)
  const [composing, setComposing] = useState(false)
  const inputRef = useRef(null)

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  useEffect(() => {
    const checkComposition = () => {
      if (inputRef.current && 'isComposing' in inputRef.current) {
        setComposing(inputRef.current.isComposing)
      }
    }

    const input = inputRef.current
    if (input) {
      input.addEventListener('compositionupdate', checkComposition)
    }

    return () => {
      if (input) {
        input.removeEventListener('compositionupdate', checkComposition)
      }
    }
  }, [])

  const formatTime = (input, previousValue) => {
    // バックスペースが押され、コロンの前にいる場合は、その前の文字を削除する。
    if (input.length < previousValue.length && previousValue.endsWith(':')) {
      return previousValue.slice(0, -2)
    }

    input = input.normalize("NFKC")
    let formattedTime = ''
    let colonAdded = false

    for (let i = 0; i < input.length; i++) {
      if (input[i] === ':') {
        if (!colonAdded) {
          // 前の文字が存在し、それが数字で、コロンの前の唯一の数字である場合
          if (i > 0 && /\d/.test(input[i - 1]) && (i === 1 || !/\d/.test(input[i - 2]))) {
            formattedTime = '0' + formattedTime // Add leading zero
          }
          formattedTime += ':'
          colonAdded = true
        }
      } else if (/\d/.test(input[i])) {
        if (formattedTime.length === 0 && parseInt(input[i]) >= 5) {
          formattedTime = '0' + input[i] // 5以上の数値の場合、最初の桁にゼロを加える。
        } else {
          formattedTime += input[i]
        }
        if (formattedTime.length === 2 && !colonAdded) {
          formattedTime += ':'
          colonAdded = true
        }
      }
    }

    return formattedTime.slice(0, 5) // 5文字以内（HH:MM形式）
  }

  const validateTime = (timeString) => {
    timeString = timeString.normalize("NFKC")

    // 入力値の検証
    const [hours, minutes] = timeString.split(':').map(Number)
    let validate = hours >= 0 && hours <= 47 && minutes >= 0 && minutes < 60

    if (validate) {
      // OKなら、フォーマットして返す
      return ('00' + hours).slice(-2) + ':' + ('00' + minutes).slice(-2)
    }

    return ""
  }


  const handleChange = (e) => {
    if (!composing) {
      const formattedValue = formatTime(e.target.value, value)
      setValue(formattedValue)
    } else {
      setValue(e.target.value)
    }
  }

  const handleBlur = (e) => {
    let v = validateTime(value)
    setValue(v)
    if (props.onChange) {
      props.onChange({ ...e, target: { ...e.target, value: v } })
    }
  }

  const handleCompositionStart = () => {
    setComposing(true)
  }

  const handleCompositionEnd = (e) => {
    setComposing(false)
    handleChange(e)
  }

  const handleFocus = () => {
    // フォーカス時にIMEの状態を確認
    if (inputRef.current && 'isComposing' in inputRef.current) {
      setComposing(inputRef.current.isComposing)
    }
  }

  return (
    <input
      className="TimeInput"
      ref={inputRef}
      type="text"
      readOnly={props.readOnly}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onCompositionStart={handleCompositionStart}
      onCompositionEnd={handleCompositionEnd}
      maxLength="5"
    />
  )
}

export default TimeInput