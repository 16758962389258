import './ConstSelector.css'

import ConstIdDialog from './ConstIdDialog'
import CellInput from './CellInput'
import { IconButton } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

import { constListData } from '../hooks/ConstListData'

import React, { useState, useEffect } from 'react'
import { useRecoilValue } from 'recoil'

const ConstSelector = (props) => {
    const constList = useRecoilValue(constListData) // 案件一覧データ（マップ構造）
    const [constId, setConstId] = useState(props.value)
    const [constDialogOpen, setConstDialogOpen] = useState(false)  // 案件選択ダイアログの表示コントロール

    useEffect(() => {
        setConstId(props.value)
    }, [props.value])

    return (
        <div className="ConstSelector">
            <ConstIdDialog open={constDialogOpen}
                canCancel={true}
                onChoise={(project) => {
                    if (props.onChangeConstId) {
                        props.onChangeConstId(project.constId)
                    }
                    setConstDialogOpen(false)
                }}
                onClose={() => {
                    setConstDialogOpen(false)
                }}
            />
            <div className={"constId"} >
                <CellInput value={constId} readOnly={props.readOnly} onChange={(event) => {
                    setConstId(event.target.value)
                    if ((!event.target.value || constList.has(event.target.value)) && props.onChangeConstId) {
                        props.onChangeConstId(event.target.value)
                    }
                }} />
            </div>
            {!props.readOnly ? (
                <span className={"icon"} >
                    <IconButton size="small"
                        aria-label="search" color="primary"
                        onClick={() => {
                            setConstDialogOpen(true)
                        }}>
                        <SearchIcon style={{ fontSize: 14 }} />
                    </IconButton>
                </span>
            ) : (
                <React.Fragment />
            )}
            <span className={"name"} >
                {constList.has(constId) ? constList.get(constId).name : ''}
            </span>
        </div>
    )
}

export default ConstSelector
