import './ConstListController.css'
import MainContent from '../common/MainContent'
import InfoBox from '../common/InfoBox'
import CellInput from '../common/CellInput'

import { formatDate } from '../utils/Utils'

import { constListData } from '../hooks/ConstListData'
import { sessionUserState } from '../hooks/SessionUserState'

import React, { useState, useEffect, useRef } from 'react'
import {
  ButtonGroup,
  Button,
  Box,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Checkbox,
  FormControlLabel,
  Tooltip,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  Popper,
  Grow,
  Paper,
  MenuList,
  MenuItem,
  ClickAwayListener
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { ViewModule, ViewList, ArrowDropDown } from '@mui/icons-material'
import { useRecoilValue } from 'recoil'

const ConstListController = (props) => {
  const initialKeyData = { searchKey: '', process: true }
  const [keyData, setKeyData] = useState(initialKeyData)
  const [listType, setListType] = useState("grid")
  const [order, setOrder] = React.useState('desc')
  const [orderBy, setOrderBy] = React.useState('constId')

  const [constList, setConstList] = useState([])

  const constMap = useRecoilValue(constListData) // 社員一覧データ（マップ構造）
  const userState = useRecoilValue(sessionUserState)

  useEffect(() => {
    setConstList(getConsts())
  }, [constMap, keyData, order, orderBy])

  useEffect(() => {
    const ls = sessionStorage.getItem("resultManage.listType")
    setListType(ls ? ls : "grid")
  }, [])

  useEffect(() => {
    sessionStorage.setItem("resultManage.listType", listType)
  }, [listType])

  const getConsts = () => {
    let list = Array.from(constMap.values()).filter((v) => {
      return (v.constId.includes(keyData.searchKey) || v.name.includes(keyData.searchKey))
        && (v.constId.startsWith("O-") || v.constId.startsWith("T-"))
        && ((userState.personalSetting?.area === "大阪" && v.constId.startsWith("O"))
          || (userState.personalSetting?.area === "東京" && v.constId.startsWith("T"))
          || userState.personalSetting?.area === "指定無し")
        && (keyData.process ? v.process === "工事進行中" : true)
    })

    list = stableSort(list, getComparator(order, orderBy))
    return list
  }

  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  };

  return (
    <MainContent className="ConstListController" style={props.style}>
      <SearchCondition value={keyData} onChangeSearchCondition={(condition) => {
        setKeyData(condition)
      }}
        onChangeListType={(listType) => {
          setListType(listType)
        }} />
      {
        (listType === "grid") ?
          (<div style={{ position: "relative", display: "flex", flexWrap: "wrap", overflowY: "auto", width: "100%", height: "calc(100% - 50px)" }}>
            {
              // constMapのvalueを一覧で表示する
              constList.map((v) => {
                return (<ConstPanel selected={v.constId === props.constId} key={v.constId} value={v} onChoiseConstId={(constId) => {
                  props.onChangeConstId(constId)
                }} />)
              })
            }
          </div>)
          :
          (
            <TableContainer sx={{ maxHeight: "calc(100% - 50px)" }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow style={{ backgroundColor: "white" }}>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={createSortHandler(headCell.id)}
                        >
                          {headCell.label}
                          {orderBy === headCell.id ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    // constMapのvalueを一覧で表示する
                    constList.map((v) => {
                      return (<ConstList key={v.constId} value={v} selected={v.constId === props.constId} onChoiseConstId={(constId) => {
                        props.onChangeConstId(constId)
                      }} />)
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
          )
      }
    </MainContent>
  )
}

const ConstPanel = (props) => {
  let imgPath = "/ConstManage/v1/constphotos/projectPhoto?constId=" + props.value.constId

  return (
    <Card id={'ANCHOR-' + props.value.constId} className="ConstPanel" style={props.selected ? { backgroundColor: "#cfc" } : {}}>
      <CardActionArea onClick={() => {
        props.onChoiseConstId(props.value.constId)
      }}>
        <a href={"#" + props.value.constId} />
        <CardMedia className="ConstImage" image={imgPath} alt="image" />
        <CardContent>
          <div className="ConstId">{props.value.constId}</div>
          <Tooltip title={props.value.name}>
            <div className="ConstName">{props.value.name}</div>
          </Tooltip>
          <hr style={{ marginTop: "2px", marginBottom: "4px" }} />
          <div className="ConstTerm">{formatDate(props.value.termFrom)}　〜　{formatDate(props.value.termTo)}</div>
          <div style={{ display: "flex", marginTop: "5px" }}>
            <div className="SalesStaff">
              <div className="Label">営業担当</div>
              <div className="Name">{props.value.salesStaff}</div>
            </div>
            <div className="ConstructionStaff">
              <div className="Label">工事担当</div>
              <div className="Name">{props.value.staff}</div>
            </div>
          </div>
          <div className="Process" style={{ backgroundColor: props.value.process === "工事進行中" ? "lightgreen" : "yellow" }}>{props.value.process}</div>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

const ConstList = (props) => {
  return (
    <TableRow id={'ANCHOR-' + props.value.constId} key={props.value.constId} className="ConstList" style={props.selected ? { backgroundColor: "#cfc" } : {}} onClick={() => {
      props.onChoiseConstId(props.value.constId)
    }}>
      <TableCell>{props.value.constId}</TableCell>
      <TableCell>{props.value.name}</TableCell>
      <TableCell>{formatDate(props.value.termFrom)}　〜　{formatDate(props.value.termTo)}</TableCell>
      <TableCell>{props.value.salesStaff}</TableCell>
      <TableCell>{props.value.staff}</TableCell>
    </TableRow>
  )
}

const SearchCondition = (props) => {
  const [searchCondition, setSearchCondition] = useState(props.value)
  const [open, setOpen] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(1)
  const anchorRef = useRef(null)

  const options = ['工事概要一覧', '請負金額入金一覧', '各現場出来高／既払／未払一覧']

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }
  const handleClose = (event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return
    }

    setOpen(false)
  }
  return (
    < div style={{ backgroundColor: "white", padding: "5px", position: "relative", display: "flex", flexDirection: "row" }}>
      <InfoBox title={"検索文字列"}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <CellInput key={"searchKey"} type="text" value={searchCondition.searchKey}
            onChange={(event) => {
              setSearchCondition({ ...searchCondition, searchKey: event.target.value })
            }}
          />
        </div>
      </InfoBox>
      <FormControlLabel style={{ whiteSpace: "nowrap" }}
        control={<Checkbox color="secondary" size="small" style={{ marginLeft: "10px" }} checked={searchCondition.process} onChange={(event) => {
          setSearchCondition({ ...searchCondition, process: event.target.checked })
        }} />}
        label="進行中の工事のみ表示"
      />
      <Button
        style={{ marginLeft: "10px" }}
        variant="contained"
        color="inherit"
        onClick={() => {
          props.onChangeSearchCondition(searchCondition)
        }} >
        検索
      </Button>

      <div style={{ width: "100%", textAlign: "right" }}>
        <ButtonGroup ref={anchorRef} variant="contained" color="inherit" style={{ color: "gray" }}>
          <Button size="small" onClick={() => {
            props.onChangeListType("grid")
          }}><ViewModule /></Button>
          <Button size="small" onClick={() => {
            props.onChangeListType("list")
          }}><ViewList />{options[selectedIndex]}</Button>
          <Button size="small" onClick={handleToggle}><ArrowDropDown /></Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 100,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper >
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" style={{ backgroundColor: "#e0e0e0", color: "gray" }} autoFocusItem>
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div >
  )
}

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const headCells = [
  { id: 'constId', numeric: false, disablePadding: true, label: "工番" },
  { id: 'name', numeric: false, disablePadding: true, label: "工事名称" },
  { id: 'termFrom', numeric: false, disablePadding: true, label: "工期" },
  { id: 'salesStaff', numeric: false, disablePadding: true, label: "営業担当" },
  { id: 'staff', numeric: false, disablePadding: true, label: "工事担当" },
]

export default ConstListController
