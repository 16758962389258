import './ConstReport.css'
import CellInput from '../common/CellInput'
import Good from '../common/Good'

import Dedura from './constReport/Dedura'
import Juki from './constReport/Juki'
import SafetyInspection from './constReport/SafetyInspection'
import RiskPrediction from './constReport/RiskPrediction'
import ActionForRisk from './constReport/ActionForRisk'
import MemberChoiser from '../common/MemberChoiser'
import ConstSelector from '../common/ConstSelector'
import ConstPhotos from './constReport/ConstPhotos'
import ProgressStatus from './constReport/ProgressStatus'
import ConstructionPeriod from './constReport/ConstructionPeriod'

import Check from './constReport/Check'
import { MediaPc, MediaSmartPhone } from '../common/Responsive'

import InfoBox from '../common/InfoBox'
import UserName from '../common/UserName'
import { errorState } from '../hooks/ErrorState'
import { sessionUserState } from '../hooks/SessionUserState'
import { constListData } from '../hooks/ConstListData'

import ConstReportService from '../../services/ConstReportService'

import React, { useState, useEffect } from 'react'
import { formatDate } from '../utils/Utils'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import DateRangeIcon from '@mui/icons-material/DateRange'

const ConstReport = (props) => {
    let constReport = props.value

    const [dedura, setDedura] = useState([])
    const [juki, setJuki] = useState([])
    const [page, setPage] = useState("today")
    const setError = useSetRecoilState(errorState) // エラーメッセージ表示用
    const userData = useRecoilValue(sessionUserState)
    const constList = useRecoilValue(constListData) // 案件一覧データ（マップ構造）

    useEffect(() => {
        if (props.readOnly) {
            return
        }
        Promise.all(
            [
                ConstReportService.loadDedura(),
                ConstReportService.loadJuki()
            ]
        ).then(
            response => {
                // state更新
                setDedura(response[0].data.objects)
                setJuki(response[1].data.objects)
            },
            error => {
                setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
            }
        ).catch(error => {
            setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
        })
    }, [])

    useEffect(() => {
        setPage(props.page ? props.page : "today")
    }, [props.page])

    useEffect(() => {
        if (constReport && props.onConstIdSelect) {
            props.onConstIdSelect(!constReport.constId)
        }
    }, [constReport])

    if (!constReport) {
        return null
    }
    const objects = [
        {
            title: "作業内容",
            today: (<CellInput line="5" value={constReport.today.workDetail} readOnly={props.readOnly}
                onChange={(event) => {
                    let today = constReport.today
                    today.workDetail = event.target.value
                    if (props.onChangeConstReport) {
                        props.onChangeConstReport({ ...constReport, today: today })
                    }
                }} />),
            tomorrow: (<CellInput line="5" value={constReport.next.workDetail} readOnly={props.readOnly}
                onChange={(event) => {
                    let next = constReport.next
                    next.workDetail = event.target.value
                    if (props.onChangeConstReport) {
                        props.onChangeConstReport({ ...constReport, next: next })
                    }
                }} />)
        },
        {
            title: "作業範囲",
            today: (<CellInput line="5" value={constReport.today.workingRange} readOnly={props.readOnly}
                onChange={(event) => {
                    let today = constReport.today
                    today.workingRange = event.target.value
                    if (props.onChangeConstReport) {

                        props.onChangeConstReport({ ...constReport, today: today })
                    }
                }} />),
            tomorrow: (<CellInput line="5" value={constReport.next.workingRange} readOnly={props.readOnly}
                onChange={(event) => {
                    let next = constReport.next
                    next.workingRange = event.target.value
                    if (props.onChangeConstReport) {
                        props.onChangeConstReport({ ...constReport, next: next })
                    }
                }} />)
        },
        {
            title: "安全点検・確認項目",
            today: (<SafetyInspection value={constReport.today.safetyInspection} readOnly={props.readOnly}
                onChangeSafetyInspection={(v) => {
                    let today = constReport.today
                    today.safetyInspection = v
                    if (props.onChangeConstReport) {
                        props.onChangeConstReport({ ...constReport, today: today })
                    }
                }}
            />),
            tomorrow: (<SafetyInspection value={constReport.next.safetyInspection} readOnly={props.readOnly}
                onChangeSafetyInspection={(v) => {
                    let next = constReport.next
                    next.safetyInspection = v
                    if (props.onChangeConstReport) {
                        props.onChangeConstReport({ ...constReport, next: next })
                    }
                }}
            />)
        },
        {
            title: "危険予知活動",
            today: (<RiskPrediction value={constReport.today.riskPrediction} readOnly={props.readOnly}
                onChangeRiskPrediction={(v) => {
                    let today = constReport.today
                    today.riskPrediction = v
                    if (props.onChangeConstReport) {

                        props.onChangeConstReport({ ...constReport, today: today })
                    }
                }}
            />),
            tomorrow: (<RiskPrediction value={constReport.next.riskPrediction} readOnly={props.readOnly}
                onChangeRiskPrediction={(v) => {
                    let next = constReport.next
                    next.riskPrediction = v
                    if (props.onChangeConstReport) {
                        props.onChangeConstReport({ ...constReport, next: next })
                    }
                }}
            />)
        },
        {
            title: "危険から身を守るための行動",
            today: (<ActionForRisk value={constReport.today.actionForRisk} readOnly={props.readOnly}
                onChangeActionForRisk={(v) => {
                    let today = constReport.today
                    today.actionForRisk = v
                    if (props.onChangeConstReport) {
                        props.onChangeConstReport({ ...constReport, today: today })
                    }
                }}
            />),
            tomorrow: (<ActionForRisk value={constReport.next.actionForRisk} readOnly={props.readOnly}
                onChangeActionForRisk={(v) => {
                    let next = constReport.next
                    next.actionForRisk = v
                    if (props.onChangeConstReport) {
                        props.onChangeConstReport({ ...constReport, next: next })
                    }
                }}
            />)
        },
        {
            title: "搬出入車両",
            today: (<CellInput line="5" value={constReport.today.vehicle} readOnly={props.readOnly}
                onChange={(event) => {
                    let today = constReport.today
                    today.vehicle = event.target.value
                    if (props.onChangeConstReport) {
                        props.onChangeConstReport({ ...constReport, today: today })
                    }
                }} />),
            tomorrow: (<CellInput line="5" value={constReport.next.vehicle} readOnly={props.readOnly}
                onChange={(event) => {
                    let next = constReport.next
                    next.vehicle = event.target.value
                    if (props.onChangeConstReport) {
                        props.onChangeConstReport({ ...constReport, next: next })
                    }
                }} />)
        },
        {
            title: "作業人員",
            today: (<Dedura list={dedura} value={constReport.today.dedura} readOnly={props.readOnly}
                onChangeDeduraList={(v) => {
                    let today = constReport.today
                    today.dedura = v
                    if (props.onChangeConstReport) {
                        props.onChangeConstReport({ ...constReport, today: today })
                    }
                }}
            />),
            tomorrow: (<Dedura list={dedura} value={constReport.next.dedura} readOnly={props.readOnly}
                onChangeDeduraList={(v) => {
                    let next = constReport.next
                    next.dedura = v
                    if (props.onChangeConstReport) {
                        props.onChangeConstReport({ ...constReport, next: next })
                    }
                }}
            />)
        },
        {
            title: "使用重機",
            today: (<Juki list={juki} value={constReport.today.juki} readOnly={props.readOnly}
                onChangeJukiList={(v) => {
                    let today = constReport.today
                    today.juki = v
                    if (props.onChangeConstReport) {
                        props.onChangeConstReport({ ...constReport, today: today })
                    }
                }}
            />),
            tomorrow: (<Juki list={juki} value={constReport.next.juki} readOnly={props.readOnly}
                onChangeJukiList={(v) => {
                    let next = constReport.next
                    next.juki = v
                    if (props.onChangeConstReport) {
                        props.onChangeConstReport({ ...constReport, next: next })
                    }
                }}
            />)
        },
        {
            title: "今月の安全宣言",
            today: (<CellInput line="5" value={constReport.today.safetyGoal} readOnly={props.readOnly}
                onChange={(event) => {
                    let today = constReport.today
                    today.safetyGoal = event.target.value
                    if (props.onChangeConstReport) {
                        props.onChangeConstReport({ ...constReport, today: today })
                    }
                }} />),
            tomorrow: (<CellInput line="5" value={constReport.next.safetyGoal} readOnly={props.readOnly}
                onChange={(event) => {
                    let next = constReport.next
                    next.safetyGoal = event.target.value
                    if (props.onChangeConstReport) {
                        props.onChangeConstReport({ ...constReport, next: next })
                    }
                }} />)
        },
        {
            title: "作業内容の反省",
            today: (<CellInput line="5" value={constReport.today.introspection} readOnly={props.readOnly}
                onChange={(event) => {
                    let today = constReport.today
                    today.introspection = event.target.value
                    if (props.onChangeConstReport) {
                        props.onChangeConstReport({ ...constReport, today: today })
                    }
                }} />),
            tomorrow: (<CellInput line="5" value={constReport.next.introspection} readOnly={props.readOnly}
                onChange={(event) => {
                    let next = constReport.next
                    next.introspection = event.target.value
                    if (props.onChangeConstReport) {
                        props.onChangeConstReport({ ...constReport, next: next })
                    }
                }} />)
        },
        {
            title: "ヒヤリハット",
            today: (<CellInput line="5" value={constReport.today.nearMiss} readOnly={props.readOnly}
                onChange={(event) => {
                    let today = constReport.today
                    today.nearMiss = event.target.value
                    if (props.onChangeConstReport) {
                        props.onChangeConstReport({ ...constReport, today: today })
                    }
                }} />),
            tomorrow: (<CellInput line="5" value={constReport.next.nearMiss} readOnly={props.readOnly}
                onChange={(event) => {
                    let next = constReport.next
                    next.nearMiss = event.target.value
                    if (props.onChangeConstReport) {
                        props.onChangeConstReport({ ...constReport, next: next })
                    }
                }} />)
        },
        {
            title: "工期",
            today: (<ConstructionPeriod value={constReport.today.constructionPeriod} readOnly={props.readOnly}
                termFrom={constList.get(constReport.constId)?.termFrom}
                termTo={constList.get(constReport.constId)?.termTo}
                onChangeConstructionPeriod={(constructionPeriod) => {
                    let today = constReport.today
                    today.constructionPeriod = constructionPeriod
                    if (props.onChangeConstReport) {
                        props.onChangeConstReport({ ...constReport, today: today })
                    }
                }} />),
            tomorrow: (<ConstructionPeriod value={constReport.next.constructionPeriod} readOnly={props.readOnly}
                termFrom={constList.get(constReport.constId)?.termFrom}
                termTo={constList.get(constReport.constId)?.termTo}
                onChangeConstructionPeriod={(constructionPeriod) => {
                    let next = constReport.next
                    next.constructionPeriod = constructionPeriod
                    if (props.onChangeConstReport) {
                        props.onChangeConstReport({ ...constReport, next: next })
                    }
                }} />)
        },
        {
            title: "関所日",
            today: (<Check value={constReport.today.check} readOnly={props.readOnly}
                onChangeCheck={(check) => {
                    let today = constReport.today
                    today.check = check
                    if (props.onChangeConstReport) {
                        props.onChangeConstReport({ ...constReport, today: today })
                    }
                }} />),
            tomorrow: (<Check value={constReport.next.check} readOnly={props.readOnly}
                onChangeCheck={(check) => {
                    let next = constReport.next
                    next.check = check
                    if (props.onChangeConstReport) {
                        props.onChangeConstReport({ ...constReport, next: next })
                    }
                }} />)
        },
        {
            title: "進捗状況",
            today: (<ProgressStatus value={constReport.today.progress} readOnly={props.readOnly}
                onChangeProgress={(value) => {
                    let today = constReport.today
                    today.progress = value
                    if (props.onChangeConstReport) {
                        props.onChangeConstReport({ ...constReport, today: today })
                    }
                }} />),
            tomorrow: (<ProgressStatus value={constReport.next.progress} readOnly={props.readOnly}
                onChangeProgress={(value) => {
                    let next = constReport.next
                    next.progress = value
                    if (props.onChangeConstReport) {
                        props.onChangeConstReport({ ...constReport, next: next })
                    }
                }} />)
        },
        {
            title: "指示内容と報告",
            today: (<CellInput line="5" value={constReport.today.instruction} readOnly={props.readOnly}
                onChange={(event) => {
                    let today = constReport.today
                    today.instruction = event.target.value
                    if (props.onChangeConstReport) {
                        props.onChangeConstReport({ ...constReport, today: today })
                    }
                }} />),
            tomorrow: (<CellInput line="5" value={constReport.next.instruction} readOnly={props.readOnly}
                onChange={(event) => {
                    let next = constReport.next
                    next.instruction = event.target.value
                    if (props.onChangeConstReport) {
                        props.onChangeConstReport({ ...constReport, next: next })
                    }
                }} />)
        },
        {
            title: "備考",
            today: (<CellInput line="5" value={constReport.today.remarks} readOnly={props.readOnly}
                onChange={(event) => {
                    let today = constReport.today
                    today.remarks = event.target.value
                    if (props.onChangeConstReport) {
                        props.onChangeConstReport({ ...constReport, today: today })
                    }
                }} />),
            tomorrow: (<CellInput line="5" value={constReport.next.remarks} readOnly={props.readOnly}
                onChange={(event) => {
                    let next = constReport.next
                    next.remarks = event.target.value
                    if (props.onChangeConstReport) {
                        props.onChangeConstReport({ ...constReport, next: next })
                    }
                }} />)
        },
    ]

    return (
        <div className="ConstReport">
            <div style={{ maxWidth: "920px", display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                <InfoBox title={"氏名"} >
                    <UserName userId={constReport.accountId} />
                </InfoBox>
                {/* <InfoBox title={"上長"} >
                    <UserChoiser error={constReport?.superiorIdError} value={constReport.superiorId} readOnly={props.readOnly} onChoiseUser={(userId) => {
                        props.onChangeConstReport({ ...constReport, superiorId: userId })
                    }} />
                </InfoBox> */}
                <InfoBox title={"上長"} >
                    <UserName userId={constReport.superiorId} />
                </InfoBox>
            </div>

            <MediaSmartPhone>
                <div className="constRow" >
                    {page === "today" ? (
                        <div className="constHeader today" style={{ justifyContent: "center" }}>
                            <span style={{ fontSize: "30px" }}>当日実績</span>
                        </div>
                    ) : (
                        <div className="constHeader tomorrow" style={{ justifyContent: "center" }}>
                            <span style={{ fontSize: "30px" }}>次の日の予定</span>
                        </div>
                    )}
                </div>
            </MediaSmartPhone>
            <div className="constRow" >
                <div className="constTitle">
                    <span>工事</span>
                </div>
                <div className="constCell constInfo">
                    <ConstSelector value={constReport.constId} readOnly={props.readOnly}
                        onChangeConstId={(constId) => {
                            props.onChangeConstId(constId)
                        }} />
                </div>
            </div>
            {page === "today" ? ( // PC or スマホ当日実績
                <div className="constRow">
                    <div className="constTitle">
                        <span>メンバー</span>
                    </div>
                    <div className="constHeader member" >
                        <MemberChoiser value={constReport.members} readOnly={props.readOnly}
                            onChange={(members) => {
                                props.onChangeConstReport({ ...constReport, members: members })
                            }}
                        />
                    </div>
                </div>
            ) : (
                <React.Fragment />
            )}

            <MediaPc>
                <div className="constRow" style={{ height: "24px" }}>
                    <div className="constTitle">
                        <span>項目</span>
                    </div>
                    <div className="constHeader today" style={{ justifyContent: "center" }}>
                        <span>当日実績</span>
                    </div>
                    <div className="constHeader tomorrow" style={{ justifyContent: "center" }}>
                        <span>次の日の予定</span>
                    </div>
                </div>
            </MediaPc>

            <div className="constRow" >
                <div className="constTitle">
                    <span>日付</span>
                </div>
                <MediaPc>
                    <div className="constCell today">
                        <div style={{ textAlign: "center", width: "100%" }}>{formatDate(constReport.today.date)}
                            <DateRangeIcon size="small" style={{ verticalAlign: "bottom", color: "#666" }}
                                onClick={(event) => {
                                    if (props.onChoiseDate) {
                                        props.onChoiseDate(event)
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="constCell tomorrow">
                        <div style={{ textAlign: "center", width: "100%" }}>{formatDate(constReport.next.date)}</div>
                    </div>
                </MediaPc>
                <MediaSmartPhone>
                    {page === "today" ? (
                        <div className="constCell today">
                            <div style={{ textAlign: "center", width: "100%" }}>{formatDate(constReport.today.date)}
                                <DateRangeIcon size="small" style={{ verticalAlign: "bottom", color: "#666" }}
                                    onClick={(event) => {
                                        if (props.onChoiseDate) {
                                            props.onChoiseDate(event)
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="constCell tomorrow">
                            <div style={{ textAlign: "center", width: "100%" }}>{formatDate(constReport.next.date)}</div>
                        </div>
                    )}
                </MediaSmartPhone>
            </div>

            {
                objects.map((v, idx) => {
                    return (
                        <React.Fragment>
                            <MediaPc>
                                <div className="constRow">
                                    <div className="constTitle">
                                        <span>{idx + 1}.{v.title}</span>
                                    </div>
                                    <div className="constCell today">
                                        {v.today}
                                    </div>
                                    <div className="constCell tomorrow">
                                        {v.tomorrow}
                                    </div>
                                </div>

                            </MediaPc>
                            <MediaSmartPhone>
                                <div className="constRow">
                                    <div className="constTitle">
                                        <span>{idx + 1}.{v.title}</span>
                                    </div>
                                </div>
                                <div className="constRow">
                                    {page === "today" ? (
                                        <div className="constCell today">
                                            {v.today}
                                        </div>
                                    ) : (
                                        <div className="constCell tomorrow">
                                            {v.tomorrow}
                                        </div>
                                    )}
                                </div>

                            </MediaSmartPhone>
                        </React.Fragment>
                    )
                })
            }
            {page === "today" ? ( // PC or スマホ当日実績
                <div className="constRow" >
                    <div className="constTitle">
                        <span>写真</span>
                    </div>
                    <div className="constCell constPhoto" >
                        <ConstPhotos style={{ minHeight: "100px" }} value={constReport.photos} />
                    </div>
                </div>
            ) : (
                <React.Fragment />
            )}
            <Good value={constReport.id} readOnly={!props.readOnly} userId={userData.userId} date={constReport.today.date} targetUserId={constReport.accountId} />
            {props.children}
            <div style={{ height: "10px" }} />
        </div >
    )
}

export default ConstReport
