import './Footer.css'

import React from 'react'

const Footer = ({ children }) => {
    return (
        <div className="Footer">
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                {children}
            </div>
        </div>
    )
}

export default Footer