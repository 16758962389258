import ConstSelector from '../../common/ConstSelector'

import React from 'react'

const ConstId = (props) => {
  return (
    <div className="ConstId constRow" style={{ flexGrow: 1 }}>
      <div className="constTitle">
        <span>工事名</span>
      </div>
      <div className="constCell constInfo">
        <ConstSelector value={props.value} readOnly={props.readOnly}
          onChangeConstId={(constId) => {
            props.onChangeConstId(constId)
          }} />
      </div>
    </div>

  )
}

export default ConstId
