import { http } from "./Config";

const requestPath = "/v1/me";

const find = () => {
  return http.get(`${requestPath}`);
};

const nextDate = (date) => {
  let parameter = encodeURIComponent(date)
  return http.get(`${requestPath}/next?date=${parameter}`)
}

const findPersonalSetting = () => {
  return http.get(`${requestPath}/personalSetting`)
}

const findSuperiorByUserId = (userId) => {
  return http.get(`${requestPath}/superior/${userId}`)
}

const putPersonalSetting = (personalSetting) => {
  return http.put(`${requestPath}/personalSetting`, personalSetting, {
    headers: {
      "content-type": "application/json",
    },
  })
}

const getTsunAGUrl = () => {
  return http.get(`${requestPath}/tsunagurl`)
}

const exports = {
  find,
  nextDate,
  findPersonalSetting,
  findSuperiorByUserId,
  putPersonalSetting,
  getTsunAGUrl,
};

export default exports;
