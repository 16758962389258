import InfoBox from '../../common/InfoBox'

import LimitedTextarea from '../../common/LimitedTextarea'
import React, { useState } from 'react'

const MonthlySafetyDeclaration = (props) => {
  const [value, setValue] = useState(props.value)
  return (
    <React.Fragment>
      <InfoBox title="安全衛生管理" style={{ width: "100%" }} />
      <div className="MonthlySafetyDeclaration constRow" >
        <div className="constTitle">
          <span>今月の安全宣言</span>
        </div>
        <div className="constCell">
          <LimitedTextarea
            maxLines={3}
            maxCharsPerLine={40}
            initialText={value}
            readOnly={props.readOnly}
            style={{ border: "none", outline: "none", width: "calc(100% - 10px)", margin: "2px", fontSize: "14px", fontWeight: "bold" }}
            onChange={(text) => {
              setValue(text)
            }}
            onBlur={(e) => {
              props.onChangeValue(value)
            }}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default MonthlySafetyDeclaration