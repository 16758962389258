import React from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const Error = (props) => {
  return (
    <Dialog
      style={props.style}
      open={props.open}
      onClose={() => {
        if (props.onOk) {
          props.onOk()
        }
      }
      }
    >
      <DialogTitle style={{ padding: "3px" }} className={"label"}>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ color: "red", ...props.textStyle }} component={"pre"} >
          {props.msg}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" size="small"
          onClick={() => {
            if (props.onOk) {
              props.onOk()
            }
          }} color="primary" autoFocus>
          ＯＫ
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Error