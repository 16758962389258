import { http } from "./Config"
const requestPath = "/v1/pdf"

/**
 * pdfを作成してダウンロードする
 * @param  templateName テンプレート名 workreport もしくは constreport
 * @param  data テンプレート変数として利用するデータ
 */
const download = (templateName, data) => {
  if (window.matchMedia && window.matchMedia('(max-device-width: 480px)').matches) {
    return downloadSmartPhone(templateName, data)
  } else {
    return downloadPc(templateName, data)
  }
}

const downloadSmartPhone = (templateName, data) => {
  return http.post(`${requestPath}/${templateName}`, data, {
    headers: {
      "content-type": "application/json",
    },
    responseType: "blob",
  })
    .then(
      response => {
        const blob = new Blob([response.data], {
          type: "application/pdf"
        });

        var url = URL.createObjectURL(blob)

        var a = document.createElement("a")
        document.body.appendChild(a)

        a.style = "display: none"
        a.href = url
        a.download = templateName + ".pdf"
        a.click()
      },
      error => {
        throw error;
      }
    )
}

const downloadPc = (templateName, data) => {
  const wait = async (ms) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, ms)
    })
  }

  const setWindowSetting = async (w, url) => {
    // ページの表示完了を待機
    while (w.document.readyState !== "complete") {
      await wait(10)
    }

    // 画面クローズの時に、生成したＵＲＬを削除
    w.onload = () => {
      w.onbeforeunload = () => {
        URL.revokeObjectURL(url)
      }
    }

    // 印刷ダイアログ表示
    w.print()
  }

  return http.post(`${requestPath}/${templateName}`, data, {
    headers: {
      "content-type": "application/json",
    },
    responseType: "blob",
  })
    .then(
      response => {
        const blob = new Blob([response.data], {
          //type: response.data.type
          type: "application/pdf"
        });

        //レスポンスヘッダからファイル名を取得
        const contentDisposition = response.headers["content-disposition"];
        getFileName(contentDisposition);

        var url = URL.createObjectURL(blob)
        let w = window.open(url)

        setWindowSetting(w, url)

        // //ダウンロードする
        // saveAs(blob, fileName);
      },
      error => {
        throw error;
      }
    )
}

const getFileName = (contentDisposition) => {
  let fileName = contentDisposition.substring(contentDisposition.indexOf("''") + 2, contentDisposition.length);
  //デコードするとスペースが"+"になるのでスペースへ置換
  fileName = decodeURI(fileName).replace(/\+/g, " ");

  return fileName;
}

const exports = {
  download,
};

export default exports;
