import { MediaSmartPhone, MediaPc } from '../../common/Responsive'
import { specialFooterState } from '../../hooks/SpecialFooterState'

import React from 'react'
import { useRecoilValue } from 'recoil'

import { IconButton, Box, Button } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

const NextArrow = (props) => {
    return (
        <IconButton className={" Pager"} style={{ position: "absolute", display: ((props.page === "today" || props.forceVisible) ? "block" : "none"), width: "40px", top: "0px", right: "5px", color: "white" }} ariaLabel="edit"
            onClick={props.onClick}
        >
            <ArrowForwardIosIcon />
        </IconButton>
    )
}

const PrevArrow = (props) => {
    return (
        <IconButton className={" Pager"} style={{ position: "absolute", display: ((props.page === "next" || props.forceVisible) ? "block" : "none"), width: "40px", top: "0px", left: "5px", color: "white" }} ariaLabel="edit"
            onClick={props.onClick}
        >
            <ArrowBackIosIcon />
        </IconButton>
    )
}

const WorkReportFooter = (props) => {
    const footerState = useRecoilValue(specialFooterState)

    return (
        <React.Fragment>
            {props.children}
            <MediaPc>
                {props.forceVisibleToday ? (
                    <PrevArrow forceVisible={props.forceVisibleToday} page={props.page} onClick={() => {
                        props.onToday()
                    }} />
                ) : null
                }
            </MediaPc>
            <MediaSmartPhone>
                <PrevArrow forceVisible={props.forceVisibleToday} page={props.page} onClick={() => {
                    props.onToday()
                }} />
            </MediaSmartPhone>
            <Box style={{ paddingRight: "10px" }}>
                <Button color="inherit" variant="contained" onClick={(event) => {
                    if (props.onPdfWorkReport) {
                        props.onPdfWorkReport(event)
                    }
                }}
                ><PictureAsPdfIcon /><MediaPc>業務日報(PDF)</MediaPc></Button>
            </Box>
            {!footerState?.readOnly ? (
                <Box style={{ paddingRight: "10px" }}>
                    <Button variant="contained" color="secondary" onClick={(event) => {
                        if (props.onSaveTemporary) {
                            props.onSaveTemporary(event)
                        }
                    }}><SaveAltIcon /><MediaPc>一時保存</MediaPc></Button>
                </Box>
            ) : (
                <React.Fragment />
            )}
            {!footerState?.readOnly ? (
                <Box style={{ paddingRight: "10px" }}>
                    <Button variant="contained" color="primary" onClick={(event) => {
                        if (props.onSent) {
                            props.onSent(event)
                        }
                    }}><SendIcon /><MediaPc>提出</MediaPc></Button>
                </Box>
            ) : (
                <React.Fragment />
            )}
            <MediaPc>
                {props.forceVisibleNext ? (
                    <NextArrow forceVisible={props.forceVisibleNext} page={props.page} onClick={() => {
                        props.onNext()
                    }} />
                ) : null
                }
            </MediaPc>
            <MediaSmartPhone>
                <NextArrow forceVisible={props.forceVisibleNext} page={props.page} onClick={() => {
                    props.onNext()
                }} />
            </MediaSmartPhone>
        </React.Fragment>
    )
}

export default WorkReportFooter