import './Actions.css'

import ConfirmationDialog from '../common/ConfirmationDialog'

import React, { useState } from 'react'
import { Menu, MenuItem } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import DeleteIcon from '@mui/icons-material/Delete'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'

const Actions = (props) => {
  let [open, setOpen] = useState(false)
  return (
    <div className="Actions">
      <ConfirmationDialog
        dialogTitle="行削除確認"
        bodyText={"行を削除します。よろしいですか？"}
        open={open}
        onOk={() => {
          props.onDelete()
          setOpen(false)
        }}
        onCancel={() => { setOpen(false) }}
        onClose={() => { setOpen(false) }}
      />

      <PopupState variant="popover" popupId="demo-popup-menu">
        {(popupState) => (
          <React.Fragment>
            <button {...bindTrigger(popupState)} ><MoreHorizIcon /></button>

            <Menu {...bindMenu(popupState)}>

              {props.task.level !== 5 ?
                <MenuItem style={{ fontSize: "14px", padding: "5px" }} onClick={props.onAddRow}><AddIcon style={{ fontSize: "14px" }} />行の追加</MenuItem>
                : null}
              <MenuItem style={{ fontSize: "14px", padding: "5px" }} onClick={props.onUp} ><KeyboardArrowUpIcon style={{ fontSize: "14px" }} />一つ上に移動</MenuItem>
              <MenuItem style={{ fontSize: "14px", padding: "5px" }} onClick={props.onDown}  ><KeyboardArrowDownIcon style={{ fontSize: "14px" }} />一つ下に移動</MenuItem>
              <MenuItem style={{ fontSize: "14px", padding: "5px", color: "red" }} onClick={() => { setOpen(true) }} ><DeleteIcon style={{ fontSize: "14px" }} />削除</MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState >
    </div>
  )
}

export default Actions
