import './VisitorInfo.css'

import ListItemInput from './ListItemInput'
import InfoBox from '../../common/InfoBox'
import { cutText } from '../../utils/Utils'

import React, { useState, useEffect } from 'react'

const VisitorInfo = (props) => {
  const [vInfo, setVInfo] = useState(props.value)

  useEffect(() => {
    setVInfo(props.value)
  }, [props.value])

  const handleRow = (row, idx) => {
    let w = vInfo.concat()

    row.companyName = cutText(row.companyName, 40)
    row.name = cutText(row.name, 40)
    row.purposeOfVisit = cutText(row.purposeOfVisit, 40)
    w[idx] = row
    setVInfo(w)
    props.onChangeVisitorInfo(w)
  }

  return (
    <div className="VisitorInfo">
      <InfoBox title="現場来場者" style={{ width: "100%" }} />
      <table className={"dataList"}>
        <thead className="scrollHead">
          <tr>
            <th className="companyName">会社名・部署名</th>
            <th className="name">氏名</th>
            <th className="purposeOfVisit">来場目的</th>
          </tr>
        </thead>
        <tbody className={"scrollBody"}>
          {vInfo.map((row, idx) => {
            return (
              <tr key={idx} style={{ verticalAlign: "top" }}>
                <td className="companyName">
                  <ListItemInput name="companyName" readOnly={props.readOnly} row={row} idx={idx} onChangeRow={handleRow} />
                </td>
                <td className="name">
                  <ListItemInput name="name" readOnly={props.readOnly} row={row} idx={idx} onChangeRow={handleRow} />
                </td>
                <td className="purposeOfVisit">
                  <ListItemInput name="purposeOfVisit" readOnly={props.readOnly} row={row} idx={idx} onChangeRow={handleRow} />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default VisitorInfo
