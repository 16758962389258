import './DailyCount.css'
import CellInput from '../../common/CellInput'

import React, { useEffect, useState } from 'react'

const DailyCount = (props) => {
  const [value, setValue] = useState(props.value)

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  return (
    <div className="DailyCount constRow" >
      <div className="constTitle">
        <span>三貴職員　本日</span>
      </div>
      <div className="constCell">
        <CellInput type="number"
          value={value}
          readOnly={props.readOnly}
          step="0.1"
          style={{ width: "40px", textAlign: "right" }}
          onChange={(e) => {
            let v = Number(e.target.value)
            if (v > 100 || v < 0) {
              return
            }
            v = Math.round(v * 10) / 10
            e.target.value = v
            setValue(v)
          }}
          onBlur={(e) => {
            props.onChangeValue(value)
          }}
        />
      </div>
    </div>

  )
}

export default DailyCount