import CellInput from '../../common/CellInput'

import { isValidDate } from '../../utils/Utils'

import React, { useState } from 'react'

const CumulativeProgress = (props) => {
  const v = Number(props.value)
  const c = Number(props.cumulate)
  const progress = (!isNaN(v) ? v : 0) + (!isNaN(c) ? c : 0)

  return (
    <div className="MeetingDate constRow" >
      <div className="constTitle">
        <span>累計の進捗</span>
      </div>
      <div className="constCell">
        <div style={{ display: "flex", flexWrap: "nowrap", alignItems: "baseline" }}>
          <CellInput type="number"
            value={progress}
            readOnly={true}
            step="0.1"
            style={{ width: "40px", textAlign: "right" }}
          /><span>％</span>
        </div>
      </div>
    </div>
  )
}

export default CumulativeProgress