import './MonthlyProgress.css'

import CellInput from '../../common/CellInput'

import React, { useState, useEffect } from 'react'

const MonthlyProgress = (props) => {

  const [progress, setProgress] = useState(props.value ? props.value : 0)

  useEffect(() => {
    setProgress(props.value ? props.value : 0)
  }, [props.value])

  return (
    <div className="MonthlyProgress constRow" >
      <div className="constTitle">
        <span>今月の進捗</span>
      </div>
      <div className="constCell">
        <div style={{ display: "flex", flexWrap: "nowrap", alignItems: "baseline" }}>
          <CellInput type="number"
            value={progress}
            maxValue={100}
            readOnly={props.readOnly}
            step="0.1"
            style={{ width: "40px", textAlign: "right" }}
            onChange={(e) => {
              let v = Number(e.target.value)
              if (v > 100 || v < 0) {
                return
              }
              v = Math.round(v * 10) / 10
              e.target.value = v
              setProgress(v)
            }}
            onBlur={(e) => {
              props.onChangeValue(progress)
            }}
          /><span>％</span>
        </div>
      </div>
    </div>
  )
}

export default MonthlyProgress