import { imageUrl, thumbUrl } from '../../../services/Config'
import Thumbnail from '../../common/Thumbnail'

import React from 'react'
import Viewer from 'react-viewer'

const ConstPhotos = (props) => {
    const [viewSource, setViewSource] = React.useState(null)
    const images = (props.value ? props.value : []).map((photo, idx) => {
        return { src: thumbUrl + "/l/?photoId=" + photo._id, alt: '', downloadUrl: imageUrl + "/?photoId=" + photo._id }
    })

    return (
        <div className="PhotoList"
            style={{ width: "100%", height: "100%" }}>
            <Viewer
                visible={viewSource !== null}
                downloadable={true}
                loop={false}
                onClose={() => { setViewSource(null) }}
                images={images}
                activeIndex={viewSource}
            />
            {
                (props.value ? props.value : []).map((photo, idx) => {
                    return (
                        <Thumbnail key={photo._id} id={"PHOTO-" + idx} index={idx} value={{ photoId: photo._id, ...photo }} onClickPicture={(photoIndex) => {
                            setViewSource(photoIndex)
                        }} />
                    )
                })
            }
        </div>
    )
}

export default ConstPhotos