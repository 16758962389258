import './PhotoInfo.css'

import CellInput from '../common/CellInput'
import { thumbUrl } from '../../services/Config'

import { turnPhoto, isSelectPhoto } from '../photo/SelectControl'
import { photoSelectState } from '../hooks/PhotoSelectState'

import React, { useState } from 'react'
import { useRecoilState } from 'recoil'

const PhotoInfo = (props) => {
    const [photoSelect, setPhotoSelect] = useRecoilState(photoSelectState)
    let [photo, setPhoto] = useState(props.photo)
    const src = thumbUrl + "/m/?photoId=" + photo.photoId

    const isSelect = () => {
        let result = isSelectPhoto(photoSelect, props.type, props.photo)
        return result
    }

    const onSelect = (event) => {
        if (props.selectable) {
            let ps = turnPhoto(photoSelect, props.type, props.photo)
            setPhotoSelect(ps)
        }

        if (props.onClickPicture) {
            props.onClickPicture(props.index)
        }
        event.stopPropagation()
    }

    const inputStyle = {
        width: "100%", border: "2px solid #ccc", margin: "0px", paddingBottom: "1px"
    }
    return (
        <div style={{ position: "relative", display: "flex", flexWrap: "nowrap", overflow: "hidden" }}
            onBlur={(event) => {
                props.onChangePhotoInfo(photo)
            }}
        >
            <div key={props.key} style={{ display: "flex", flexDirection: "row", width: "100%", backgroundColor: "white" }}>
                <div style={{ backgroundColor: isSelect() ? "red" : "#ddf", width: "5px", margin: "2px", cursor: "pointer" }}
                    onClick={(event) => {
                        onSelect(event)
                    }}
                    onTouchEnd={(event) => {
                        onSelect(event)
                    }}
                />
                <Image src={src} width="auto" height="172px" style={{ cursor: "pointer" }} readOnly={props.readOnly}
                    onClick={(event) => {
                        onSelect(event)
                    }}
                />
                <div style={{ marginTop: "2px", flexGrow: 1, marginRight: "5px" }}>
                    <div style={{ position: "relative", display: "block" }} >
                        <CellInput style={inputStyle} type="date" readOnly={props.readOnly} defaultValue={fmt(new Date(photo.day))} onInput={(event) => {
                            setPhoto({ ...photo, day: event.target.value ? new Date(event.target.value) : "" })
                        }}
                        />
                    </div>
                    <div style={{ position: "relative", display: "block" }} >
                        <CellInput style={inputStyle} type="text" readOnly={props.readOnly} defaultValue={photo.constClassName} placeholder="工種" onInput={(event) => { setPhoto({ ...photo, constClassName: event.target.value }) }} />
                    </div>
                    <div style={{ position: "relative", display: "block" }} >
                        <CellInput style={inputStyle} type="text" readOnly={props.readOnly} defaultValue={photo.constTypeName} placeholder="工種詳細" onInput={(event) => { setPhoto({ ...photo, constTypeName: event.target.value }) }} />
                    </div>
                    <div style={{ position: "relative", display: "block" }}>
                        <span style={{ display: "inline-block" }}>
                            <CellInput style={{ ...inputStyle, width: "60px" }} type="text" readOnly={props.readOnly} defaultValue={photo.floor} placeholder="階数" onInput={(event) => { setPhoto({ ...photo, floor: event.target.value }) }} />
                        </span>
                        &nbsp;階
                    </div>
                    <div style={{ position: "relative", display: "block" }} >
                        <CellInput style={inputStyle} line={4} type="text" readOnly={props.readOnly} maxRows={4} defaultValue={photo.description} placeholder="詳細" onInput={(event) => { setPhoto({ ...photo, description: event.target.value }) }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

const Image = (props) => {
    let width = props.width
    let events = props.readOnly ? {} : { onClick: (event) => { props.onClick(event) }, onTouchEnd: (event) => { props.onClick(event) } }
    if (!width) {
        width = "130px"
    }
    return (
        <div style={{ margin: "2px", cursor: "pointer" }} >
            <img src={props.src} width={width} height={props.height} alt={props.src} draggable="false" {...events} />
        </div>
    )
}

const fmt = (dt) => {
    let y = dt.getFullYear()
    let m = ("00" + (dt.getMonth() + 1)).slice(-2)
    let d = ("00" + dt.getDate()).slice(-2)
    return `${y}-${m}-${d}`
}

export default PhotoInfo