import './LimitedTextarea.css'

import React, { useState, useRef, useCallback } from 'react'

const LimitedTextarea = ({
  maxLines = 5,
  maxCharsPerLine = 30,
  onChange,
  onBlur,
  className = '',
  style = {},
  containerClassName = '',
  containerStyle = {},
  initialText = '',
  placeholder = '',
  readOnly = false
}) => {
  const [text, setText] = useState(initialText)
  const [isComposing, setIsComposing] = useState(false)
  const textareaRef = useRef(null)

  const truncateText = useCallback((text, maxLength) => {
    let result = ''
    let currentLength = 0

    for (let i = 0; i < text.length; i++) {
      const char = text[i]
      const charLength = /[\u0000-\u00ff]/.test(char) ? 1 : 2

      if (currentLength + charLength > maxLength) {
        break
      }

      result += char
      currentLength += charLength
    }

    return result
  }, [])

  const processInput = useCallback((inputText) => {
    const lines = inputText.split('\n')
    const newLines = lines.slice(0, maxLines).map(line => truncateText(line, maxCharsPerLine))
    return newLines.join('\n')
  }, [maxLines, maxCharsPerLine, truncateText])

  const handleInput = useCallback((e) => {
    if (readOnly) return

    const inputText = e.target.value

    if (!isComposing) {
      const newText = processInput(inputText)
      setText(newText)
      onChange && onChange(newText)
    } else {
      setText(inputText)
    }
  }, [isComposing, processInput, onChange, readOnly])

  const handleCompositionStart = useCallback(() => {
    setIsComposing(true)
  }, [])

  const handleCompositionEnd = useCallback(() => {
    setIsComposing(false)
    if (textareaRef.current) {
      const newText = processInput(textareaRef.current.value)
      setText(newText)
      onChange && onChange(newText)
    }
  }, [processInput, onChange])

  const handleBlur = useCallback((e) => {
    if (readOnly) return

    onBlur && onBlur(e)
  }, [readOnly, onBlur])

  return (
    <div className={`${containerClassName}`} style={containerStyle}>
      <textarea
        ref={textareaRef}
        value={text}
        onChange={handleInput}
        onCompositionStart={handleCompositionStart}
        onCompositionEnd={handleCompositionEnd}
        onBlur={handleBlur}
        rows={maxLines}
        cols={maxCharsPerLine}
        className={`LimitedTextarea ${className}`}
        style={{
          height: (maxLines * 20) + "px",
          width: (maxCharsPerLine * 8) + "px",
          ...style
        }}
        placeholder={placeholder}
        readOnly={readOnly}
      />
    </div>
  )
}

export default LimitedTextarea