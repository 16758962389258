import './Dedura.css'

import CellInput from '../../common/CellInput'

import React, { useState, useEffect } from 'react'

const Dedura = (props) => {
    const [deduraList, setDeduraList] = useState(() => {
        // 初回レンダリング時のみ処理
        return checkRows(props.value) // 表の最下行に空行を追加する
    })

    useEffect(() => {
        setDeduraList(checkRows(props.value))
    }, [props.value])

    const contractList = [
        "元請",
        "一次",
        "二次",
        "三次",
        "四次",
    ]

    return (
        <div className="Dedura">
            <datalist id="deduraList">
                {
                    props.list.map(v => (<option key={v.name} value={v.name} />))
                }
            </datalist>
            <datalist id="contractList">
                {
                    contractList.map(v => (<option key={v} value={v} />))
                }
            </datalist>
            <table className={"dataList"}>
                <thead className={"scrollHead"}>
                    <tr>
                        <th className={"contract"} style={{ textAlign: "center" }}>請</th>
                        <th className={"dedura"} style={{ textAlign: "center" }}>職種</th>
                        <th className={"company"} style={{ textAlign: "center" }}>会社<br />職長名</th>
                        <th className={"count"} style={{ textAlign: "center" }}>人数</th>
                    </tr>
                </thead>
                <thead className={"scrollBody"}>
                    {deduraList.map((row, idx) => {
                        return (
                            <tr key={idx} style={{ verticalAlign: "top" }}>
                                <td className={"contract"}>
                                    <CellInput value={row.contract} readOnly={props.readOnly} list="contractList"
                                        onChange={(event) => {
                                            let v = event.target.value
                                            deduraList[idx].contract = v
                                            setDeduraList(checkRows(deduraList))
                                            props.onChangeDeduraList(trimRow(deduraList))
                                        }}
                                    />
                                </td>
                                <td className={"dedura"}>
                                    <CellInput value={row.dedura} readOnly={props.readOnly} list="deduraList"
                                        onChange={(event) => {
                                            let v = event.target.value
                                            deduraList[idx].dedura = v
                                            setDeduraList(checkRows(deduraList))
                                            props.onChangeDeduraList(trimRow(deduraList))
                                        }}
                                    />
                                </td>
                                <td className={"company"}>
                                    <CellInput value={row.company} readOnly={props.readOnly}
                                        onChange={(event) => {
                                            let v = event.target.value
                                            deduraList[idx].company = v
                                            setDeduraList(checkRows(deduraList))
                                            props.onChangeDeduraList(trimRow(deduraList))
                                        }}
                                    />
                                    <CellInput value={row.foreman} readOnly={props.readOnly}
                                        onChange={(event) => {
                                            let v = event.target.value
                                            deduraList[idx].foreman = v
                                            setDeduraList(checkRows(deduraList))
                                            props.onChangeDeduraList(trimRow(deduraList))
                                        }}
                                    />
                                </td>
                                <td className={"count"}>
                                    <CellInput type="number" style={{ textAlign: "right" }} value={row.count} readOnly={props.readOnly}
                                        onChange={(event) => {
                                            let v = Number.parseFloat(event.target.value)
                                            if (Number.isNaN(v)) {
                                                v = ''
                                            }
                                            deduraList[idx].count = v
                                            setDeduraList(checkRows(deduraList))
                                            props.onChangeDeduraList(trimRow(deduraList))
                                        }}
                                    />
                                </td>
                            </tr>
                        )
                    })}
                </thead>
            </table>
        </div>
    )
}

const isEmpty = (o) => {
    return (o.contract === '' && o.dedura === '' && o.company === '' && o.foreman === '' && (!(o.count) || o.count === '' || Number(o.count) === 0))
}

const newData = { contract: '', dedura: '', company: '', foreman: '', count: '' }

const trimRow = (values) => {
    return values.slice().filter((row) => { return !isEmpty(row) }).map(v => v)
}
const checkRows = (values) => {
    if (!values) {
        values = []
    }
    values = trimRow(values)
    values.push({ ...newData })
    return values
}


export default Dedura
