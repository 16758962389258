import React from 'react'

import { Select, MenuItem } from '@mui/material'

const PrintQuolityChoiser = (props) => {
  const printQuolityList = [
    { q: "s", name: "低品質" },
    { q: "m", name: "標準" },
    { q: "l", name: "高品質" },
    { q: "ul", name: "最高品質（PDF作成に時間が掛かります）" },
  ]
  return (
    <Select
      disableUnderline
      style={{ fontSize: "13px", height: "30px" }}
      value={props.value}
      onChange={(event) => {
        if (props.onChange) {
          props.onChange(event)
        }
      }}>
      {printQuolityList.map((printQuolity) => {
        return (
          <MenuItem value={printQuolity.q} selected={printQuolity === props.value}>{printQuolity.name}</MenuItem>
        )
      })}
    </Select>
  )
}

export default PrintQuolityChoiser
