import axios from "axios"
import LoginService from "./LoginService"
const baseUrl = "/ConstManage"

const usersUrl = baseUrl + "/v1/users/user"
const projectsUrl = baseUrl + "/v1/users/project"
const imageUrl = baseUrl + "/v1/camera/image"
const thumbUrl = baseUrl + "/v1/camera/thumb"

const http = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-type": "x-www-form-urlencoded",
    'X-Requested-With': 'XMLHttpRequest'
  },
  responseType: 'json',
  withCredentials: true, // cookieを送信する
})

let progress = 0
export { progress, http, baseUrl, usersUrl, projectsUrl, imageUrl, thumbUrl }

const incProgress = () => {
  progress += 1
  let progressIndicator = document.getElementById("ProgressIndicator")
  if (progressIndicator) {
    setTimeout(() => {
      if (progress) {
        progressIndicator.style.visibility = "visible"
      }
    }, 10)
  }
}

const decProgress = () => {
  progress -= 1
  if (!progress) {
    let progressIndicator = document.getElementById("ProgressIndicator")
    if (progressIndicator) {
      progressIndicator.style.visibility = "hidden"
    }
  }
}

// axios共通エラーハンドリング
http.interceptors.request.use((config) => {
  console.log("axios.interceptiors.request called")
  incProgress()
  return config
})

http.interceptors.response.use((response) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  console.log("axios.interceptiors.response called")
  decProgress()
  return response;
}, function (error) {
  console.log("axios.interceptiors.error called")
  decProgress()
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  switch (error.response?.status) {
    case 401:
      // 未ログイン状態の場合、saml認証をする
      LoginService.login();
      return false;
    default:
      return Promise.reject(error)
  }
})