import { thumbUrl } from '../../services/Config'
import { photoSelectState } from '../hooks/PhotoSelectState'

import React from 'react'
import { useRecoilValue } from 'recoil'

const Draggable = (props) => {
    const selectPhotos = useRecoilValue(photoSelectState)

    const photos = []
    for (let key of selectPhotos.photos.keys()) {
        if (photos.length < 3) {
            photos.push(thumbUrl + "/s/?photoId=" + selectPhotos.photos.get(key).photoId)
        }
    }

    return (
        <div
        // draggable={false}
        >
            {props.children}
            <div style={{ position: "absolute", display: "block", top: "-1000px", left: "-1000px", zIndex: 1000 }} id="draggablePhotos">
                {photos.map((photo, idx) => {
                    return (<img alt="" style={{ position: "absolute", top: idx * 10, left: idx * 10, border: "1px solid #888" }} src={photo} width="128px" />)
                }).reverse()}
            </div>
        </div>
    )
}

export default Draggable
