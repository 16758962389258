import AllPhotos from './AllPhotos'
import Book from './Book'

import { constPhotoState } from '../hooks/ConstPhotoState'

import React, { useState, useEffect, useRef } from 'react'
import { useRecoilValue } from 'recoil'

const BookEditor = (props) => {
    const [photoEditors, setPhotoEditors] = useState([])    // ウィンドウのオブジェクトをマップとして作成
    const data = useRecoilValue(constPhotoState)

    const setMostFront = (mostFront) => {
        let p = photoEditors.slice()
        const target = p.find((row) => { return row.key === mostFront })
        p = p.filter((row) => { return row.key !== mostFront })
        p.push(target)
        setPhotoEditors(p)
    }

    useEffect(() => {
        let windowObjects = []
        {
            const key = "KEY_allPhotos"
            windowObjects.push({
                key: key, type: "allPhotos", data: data.allPhotos
            })
        }
        data.categories.forEach((category, idx) => {
            const key = "KEY_category-" + category.constCategory
            windowObjects.push({
                key: key, type: "category", data: category
            })
        })
        data.userAllocated.forEach((user, idx) => {
            const key = "KEY_" + user.constCategory
            windowObjects.push({
                key: key, type: "user", data: user
            })
        })

        setPhotoEditors(windowObjects)
    }, [data])

    const ref = useRef()
    return (
        <div className="BookEditor" style={props.style} ref={ref}>
            {
                photoEditors.map((row) => {
                    if (row.type === "allPhotos") {
                        return (
                            <AllPhotos key={row.key} constId={data.constId} data={row.data} connector={props.connector}
                                onWindowStateChanges={() => {
                                    setMostFront(row.key)
                                }}
                                onClick={() => {
                                    setMostFront(row.key)
                                }}
                            />)
                    } else if (row.type === "category") {
                        return (
                            <Book key={row.key} constId={data.constId} data={row.data} connector={props.connector}
                                onWindowStateChanges={() => {
                                    setMostFront(row.key)
                                }}
                                onClick={() => {
                                    setMostFront(row.key)
                                }}
                            />
                        )
                    } else {
                        return (
                            <Book key={row.key} constId={data.constId} data={row.data} connector={props.connector}
                                onWindowStateChanges={() => {
                                    setMostFront(row.key)
                                }}
                                onClick={() => {
                                    setMostFront(row.key)
                                }}
                            />
                        )
                    }
                })
            }
        </div>
    )
}

export default BookEditor