import './MeetingDate.css'

import CellInput from '../../common/CellInput'
import TimeInput from "../../common/TimeInput"

import { isValidDate } from '../../utils/Utils'

import React, { useEffect, useState } from 'react'

const chk = (v) => {
  if (typeof v === 'string') {
    v = new Date(v)
  }
  return v
}
const fmt = (dt) => {
  if (!dt) return ""

  let y = dt.getFullYear()
  let m = ("00" + (dt.getMonth() + 1)).slice(-2)
  let d = ("00" + dt.getDate()).slice(-2)

  return `${y}-${m}-${d}`
}

const MeetingDate = (props) => {
  const [meetingDate, setMeetingDate] = useState(chk(props.value))
  const [meetingTime, setMeetingTime] = useState(props.meetingTime)

  useEffect(() => {
    setMeetingDate(chk(props.value))
    setMeetingTime(props.meetingTime)
  }, [props.value, props.meetingTime])

  return (
    <div className="MeetingDate constRow" >
      <div className="constTitle">
        <span>打合日時</span>
      </div>
      <div className="constCell">
        <CellInput type="date" value={fmt(meetingDate)}
          readOnly={props.readOnly}
          style={{ width: "120px" }}
          onChange={(e) => {
            setMeetingDate(chk(e.target.value))
          }}
          onBlur={(e) => {
            props.onChangeMeetingDate(meetingDate)
          }}
        />
        <TimeInput
          value={meetingTime}
          readOnly={props.readOnly}
          onChange={(e) => {
            setMeetingTime(e.target.value)
            props.onChangeMeetingTime(e.target.value)
          }}
        />
      </div>
    </div>
  )
}

export default MeetingDate