import './ConstPhoto.css'

import Header from './common/Header'
import Footer from './common/Footer'
import Content from './common/Content'
import MainContent from './common/MainContent'

import Const from './photo/Const'
import BookList from './photo/BookList'
import BookEditor from './photo/BookEditor'
import FooterControls from './photo/FooterControls'
import TrashBox from './photo/TrashBox'

import Draggable from './photo/Draggable'

import React, { useState } from 'react'
import { Transition } from 'react-transition-group'

const ConstPhoto = () => {
    let connector = { allPhotos: {}, trashBox: {} }

    return (
        <Content>
            <Header title="工事写真編集" />
            <MainContent className="ConstPhoto ">
                <Main connector={connector} />
                <TrashBox key="trashBox" connector={connector} style={{ position: "absolute", right: "10px", bottom: "10px" }} />
            </MainContent>
            <Footer>
                <FooterControls connector={connector} />
            </Footer>
        </Content>
    )
}

const Main = (props) => {
    let connector = props.connector

    const [booksState, setBooksState] = useState(true)

    const bookListPosition = {
        entering: { left: '0px', transition: 'all 300ms ease' },
        entered: { left: '0px' },
        exiting: { left: '-300px', transition: 'all 300ms ease' },
        exited: { left: '-300px' },
    }

    const bookSliderPosition = {
        entering: { left: '290px', opacity: 0.5, transition: 'all 300ms ease' },
        entered: { left: '290px', opacity: 0.5, },
        exiting: { left: '0px', backgroundColor: "#ddd", opacity: 1.0, transition: 'all 300ms ease' },
        exited: { left: '0px', backgroundColor: "#ddd", opacity: 1.0, },
    }

    const bookEditorPosition = {
        entering: { left: '310px', transition: 'all 300ms ease' },
        entered: { left: '310px' },
        exiting: { left: '20px', transition: 'all 300ms ease' },
        exited: { left: '20px' },
    }

    return (
        <Transition in={booksState} timeout={1500}>
            {(state) => (
                <div style={{ position: "relative", display: "flex", flexDirection: "row", width: "100vw", flexGrow: 1 }}>
                    <Const connector={connector} />
                    <BookList style={bookListPosition[state]} connector={connector} />
                    <div className="BookSlider" style={bookSliderPosition[state]} onClick={() => { setBooksState(!booksState) }}>
                        <div >{booksState ? "＜" : "＞"}</div>
                    </div>

                    <div style={{ width: "10px" }} />
                    < Draggable >
                        <BookEditor style={bookEditorPosition[state]} connector={connector} >
                        </BookEditor>
                    </Draggable>
                </div>
            )}
        </Transition>
    )
}

export default ConstPhoto
