import './WorkReportHistory.css'

import UserName from '../common/UserName'
import DateTime from '../common/DateTime'
import AlreadyReads from '../common/AlreadyReads'

import React from 'react'
import { Box, Avatar } from '@mui/material'
import { red, blue, green } from '@mui/material/colors'

const WorkReportHistory = (props) => {
    return (
        (props.value && props.value.histories.length > 0) ? (
            <div className="WorkReportHistory" style={{ backgroundColor: "lightgray" }}>
                <div >承認履歴</div>
                {
                    props.value.histories.map((r) => {
                        return (<HistoryRow key={r} value={r} />)
                    })
                }
            </div >
        ) : (
            <React.Fragment />
        )
    )
}

const HistoryRow = (props) => {
    const colors = {
        0: { tag: "処", color: "textPrimary" },  // 処理中
        1: { tag: "承", color: green[500] },  // 承認
        2: { tag: "申", color: blue[500] },  // 申請
        9: { tag: "否", color: red[500] },  // 否認
    }

    const stat = colors[props.value.result]
    return (
        <div className="HistoryRow" style={{ margin: "4px", backgroundColor: "white" }}>
            <Box component="div" style={{ padding: "4px", display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                <Avatar style={{ backgroundColor: stat.color }}>{stat.tag}</Avatar>
                <div style={{ marginLeft: "10px", width: "200px" }}>
                    <div style={{ fontSize: "8px", color: "gray" }} ><DateTime value={props.value.endDate} />&nbsp;</div>
                    <div style={{ fontSize: "16px" }} ><UserName userId={props.value.approverId} /></div>
                    <AlreadyReads value={props.value.readCheck} myself={props.value.approverId} otherComment={true} />
                </div>
                {props.value.result !== 0 ? (
                    <div className="message" style={{ color: props.value.comment ? stat.color : "#888" }} >
                        <pre>{props.value.comment ? props.value.comment : "コメント無し"}</pre>
                    </div>
                ) : null}
            </Box>
        </div>
    )
}
export default WorkReportHistory
