import './CellInput.css'

import { formatDate } from '../utils/Utils'
import React, { useState, useEffect } from 'react'
import { TextareaAutosize } from '@mui/material'

const CellInput = (props) => {
    const [errorMessage, setErrorMessage] = useState(props.error)
    const className = "CellInput" + (props.className ? " " + props.className : "")
    const line = props.line ? parseInt(props.line, 10) : 1

    useEffect(() => {
        setErrorMessage(props.error)
    }, [props.error])

    if (props.readOnly) {
        let value = cvt(props.defaultValue ? props.defaultValue : props.value, props.type)
        return (
            <pre {...props} className={className}
                style={{
                    ...props.style,
                    backgroundColor: "white",
                    overflow: "hidden",
                    overflowWrap: "break-all",
                    whiteSpace: line === 1 ? "no-wrap" : "pre-wrap",
                    fontSize: "13.3333px",
                    fontFamily: "Arial",
                    color: "#222",
                    paddingTop: "3px",
                    paddingBottom: "0px",
                    marginTop: "1px",
                    marginBottom: "1px",
                    height: line === 1 ? "19px" : "auto",
                    lineHeight: line === 1 ? "19px" : "auto",
                    verticalAlign: "middle"
                }} title={props.title ? props.title : value}

            >
                {value ? value : '\u00A0'}
            </ pre>
        )
    }
    if (line > 1) {
        return (
            <React.Fragment>
                <TextareaAutosize readOnly={props.readOnly} minRows={line} onKeyDownCapture={() => {
                    setErrorMessage('')
                }} {...props} className={className} >
                    {props.value}
                </TextareaAutosize>
                <div style={{ position: "relative", display: errorMessage ? "block" : "none", whiteSpace: "nowrap" }}><ErrorDisplay message={errorMessage} /></div>
            </React.Fragment>
        )
    } else {
        return (
            <React.Fragment>
                <input type="text" ref={props?.inputRef} readOnly={props.readOnly} title={props.title ? props.title : props.value}
                    maxLength={props.maxLength}
                    onChangeCapture={() => {
                        setErrorMessage('')
                    }}
                    {...props} className={className} />
                <div style={{ position: "relative", display: errorMessage ? "block" : "none", whiteSpace: "nowrap" }}><ErrorDisplay message={errorMessage} /></div>
            </React.Fragment>
        )
    }
}

const cvt = (value, type) => {
    if (value && type === "date") {
        value = formatDate(new Date(value))
    }
    if (value === "") {
        value = "　"
    }
    return value
}

const ErrorDisplay = (props) => {
    return (
        <div style={{ left: "10px", backgroundColor: "pink", border: "1px solid #ccc", zIndex: 1, position: "absolute", color: "red", fontWeight: "bold" }}>{props.message}</div>
    )
}

export default CellInput
