import { http } from "./Config";

const requestPath = "/v1/role";

export const load = (condition) => {
    return http.get(`${requestPath}`)
}

export const save = (data) => {
    return http.post(`${requestPath}`, data, {
        headers: {
            "content-type": "application/json",
        },
    })
}

const exports = {
    load,
    save,
}

export default exports;
