import { http } from "./Config";

const requestPath = "/v1/constphotos";

export const save = (workData) => {
    return http.post(`${requestPath}`, workData, {
        headers: {
            "content-type": "application/json",
        },
    })
}

export const load = (constId) => {
    return http.get(`${requestPath}?constId=${constId}`)
}

const exports = {
    save,
    load,
}

export default exports;
