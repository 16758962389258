import "./Overtime.css"

import CellInput from "../../common/CellInput"
import TimeInput from "../../common/TimeInput"

import React from 'react'

const Overtime = (props) => {
  let v = props.value
  let commentDisplay = (props.readOnly ? v?.overtimeComment : (v?.startTime !== v?.defaultStartTime || v?.endTime !== v?.defaultEndTime)) ? 'inline' : 'none'
  return (
    <span className="OverTime">
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>勤務時間：</div>
      <div><TimeInput readOnly={props.readOnly} value={v?.startTime ? v?.startTime : ""}
        onChange={(event) => {
          props.onChangeOvertime({ ...v, startTime: event.target.value })
        }} /></div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "30px" }}>〜</div>
      <div><TimeInput readOnly={props.readOnly} value={v?.endTime ? v?.endTime : ""}
        onChange={(event) => {
          props.onChangeOvertime({ ...v, endTime: event.target.value })
        }} /></div>
      <div style={{ width: "100%", display: commentDisplay }}>理由</div>
      <div style={{ width: "100%", display: commentDisplay, marginLeft: "10px", marginRight: "10px" }}>
        <CellInput readOnly={props.readOnly} line={3} value={v?.overtimeComment ? v?.overtimeComment : ""}
          onChange={(event) => {
            props.onChangeOvertime({ ...v, overtimeComment: event.target.value })
          }} />
      </div>
    </span >
  )
}

export default Overtime
