import './ConstructionPeriod.css'

import CellInput from '../../common/CellInput'
import { formatDate } from '../../utils/Utils'

import React, { useEffect, useState } from 'react'

const formatForDisplay = (dt) => {
  if (!dt) {
    return ""
  }
  if (typeof (dt) === 'string') {
    dt = new Date(dt)
  }
  let y = dt.getFullYear()
  let m = ("00" + (dt.getMonth() + 1)).slice(-2)
  let d = ("00" + dt.getDate()).slice(-2)
  let idx = dt.getDay()
  let w = '日月火水木金土'[idx]
  return (
    "" + y + "/" + m + "/" + d + "(" + w + ")"
  )
}

const ConstructionPeriod = (props) => {
  const [constructionPeriod, setConstructionPeriod] = useState({
    term: formatForDisplay(props.termFrom) + (props.termTo ? "〜" : "") + formatForDisplay(props.termTo),
    envTargetPeriod: null,
    customerApprovalPeriod: null,
  })

  useEffect(() => {
    setConstructionPeriod({ ...props.value, term: constructionPeriod.term })
  }, [props.value])

  useEffect(() => {
    let term = formatForDisplay(props.termFrom) + (props.termTo ? "〜" : "") + formatForDisplay(props.termTo)
    let v = { ...props.value, term: term }
    setConstructionPeriod(v)
    props.onChangeConstructionPeriod(v)
  }, [props.termFrom, props.termTo])

  const fmt = (dt) => {
    if (!dt) return null
    if (typeof (dt) === 'string') {
      dt = new Date(dt)
    }
    let y = dt.getFullYear()
    let m = ("00" + (dt.getMonth() + 1)).slice(-2)
    let d = ("00" + dt.getDate()).slice(-2)
    return `${y}-${m}-${d}`
  }

  return (
    <div className="ConstructionPeriod" style={{ width: "100%" }}>
      <div>契約工期　　：{formatDate(props.termFrom)}{props.termTo ? "〜" : ""}{formatDate(props.termTo)}</div><hr style={{ width: "100%" }} />
      <div style={{ display: "flex" }}>
        <div>完了目標工期：</div>
        <CellInput type="date" style={{ width: "150px" }} value={fmt(constructionPeriod.envTargetPeriod)} readOnly={props.readOnly}
          onChange={(event) => {
            let date = (event.target.value) ? new Date(event.target.value) : null
            let v = { ...constructionPeriod, envTargetPeriod: date }
            setConstructionPeriod(v)
            props.onChangeConstructionPeriod(v)
          }}
        />
      </div>
      <div style={{ display: "flex" }}>
        <div>客先承認工期：</div>
        <CellInput type="date" style={{ width: "150px" }} value={fmt(constructionPeriod.customerApprovalPeriod)} readOnly={props.readOnly}
          onChange={(event) => {
            let date = (event.target.value) ? new Date(event.target.value) : null
            let v = { ...constructionPeriod, customerApprovalPeriod: date }
            setConstructionPeriod(v)
            props.onChangeConstructionPeriod(v)
          }}
        />
      </div>
    </div>
  )
}

export default ConstructionPeriod