import { atom } from 'recoil'

export const specialFooterState = atom({
    key: 'specialFooterState',
    default: { mode: "", readOnly: true },
    status: 0
})

export const WORK_REPORT = "workReport"
export const CONST_REPORT = "constReport"
