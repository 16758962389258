import './ConstManageChoiser.css'

import MainContent from '../common/MainContent'
import ConstSchedule from './ConstSchedule'
import ConstStagePlan from './ConstStagePlan'
import CellInput from '../common/CellInput'
import JpnDate from '../common/JpnDate'
import { summary } from './SummaryLogic'
import { calcMonthRange } from './ResultManageUtil'

import { constListData } from '../hooks/ConstListData'
import { resultTabIndex } from '../hooks/ResultTabIndex'
import ConfirmationDialog from '../common/ConfirmationDialog'
import FileUploadDialog from '../common/FileUploadDialog'
import { errorState } from '../hooks/ErrorState'
import { messageState } from '../hooks/MessageState'

import QuotationService from '../../services/QuotationService'
import ExcelService from '../../services/ExcelService'
import CsvService from '../../services/CsvService'

import { resultSaveState } from '../hooks/ResultSaveState'
import { resultOutputExcelState } from '../hooks/ResultOutputExcelState'
import { resultImportCsvState } from '../hooks/ResultImportCsvState'
import { resultExportCsvState } from '../hooks/ResultExportCsvState'
import { taxInfoState } from '../hooks/TaxInfoState'

import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'

import 'react-responsive-tabs/styles.css'
import {
  CardContent,
  CardMedia,
  Tooltip,
  Tab
} from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'

const StyledTabPanel = styled(TabPanel)`
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px;
`

const ConstManageChoiser = (props) => {
  const constMap = useRecoilValue(constListData) // 社員一覧データ（マップ構造）
  const [constInfo, setConstInfo] = useState({})
  const [quote, setQuote] = useState({ constId: null, termFrom: null, termTo: null, details: [] })
  const [tabIndex, setTabIndex] = useRecoilState(resultTabIndex) // タブの切り替え
  const [save, setSave] = useRecoilState(resultSaveState)
  const [outputExcel, setOutputExcel] = useRecoilState(resultOutputExcelState)
  const [importCsv, setImportCsv] = useRecoilState(resultImportCsvState)
  const [exportCsv, setExportCsv] = useRecoilState(resultExportCsvState)
  const taxInfo = useRecoilValue(taxInfoState)
  const [changeSchedule, setChangeSchedule] = useState(false)
  const [open, setOpen] = useState(false)

  const setError = useSetRecoilState(errorState) // エラーメッセージ表示用
  const setMessage = useSetRecoilState(messageState) // メッセージ表示用

  useEffect(() => {
    if (props.constId) {
      loadData(props.constId)
    }
  }, [props.constId])

  const loadData = (constId) => {
    QuotationService.getQuote(constId)
      .then(
        response => {
          let q = response.data
          setChangeSchedule(q.initialize)
          let quote = buildQuoteDetail(q)
          setQuote(quote)
        },
        error => {
          setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
        }
      ).catch(error => {
        setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
      })
  }

  const saveData = () => {
    const q = alignDetails({ ...quote })
    let details = q.details.concat().map((row) => {
      let vols = []
      row.vols.forEach((v, k) => {
        v = { year: Number(k.split("-")[0]), month: Number(k.split("-")[1]), vol: v.vol, price: v.price }
        vols.push(v)
      })
      return { ...row, vols: vols }
    })

    let resultVols = []
    q.result.vols.forEach((v, k) => {
      v = { year: Number(k.split("-")[0]), month: Number(k.split("-")[1]), vol: v.vol, price: v.price }
      resultVols.push(v)
    })

    let quoteData = {
      constId: props.constId,
      termFrom: new Date(q.termFrom),
      termTo: new Date(q.termTo),
      initialize: false,
      details: details,
      result: { ...q.result, vols: resultVols }
    }

    QuotationService.saveQuote(quoteData)
      .then(
        response => {
          setMessage({ msg: `保存を完了しました。`, open: true, title: '保存完了' })
        },
        error => {
          setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
        }
      ).catch(error => {
        setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
      }
      )
  }

  const exportExcel = () => {
    const q = alignDetails({ ...quote })
    let details = q.details.concat().map((row) => {
      let vols = []
      row.vols.forEach((v, k) => {
        v = { year: Number(k.split("-")[0]), month: Number(k.split("-")[1]), vol: v.vol, price: v.price }
        vols.push(v)
      })
      return { ...row, vols: vols }
    })

    let summarys = summary(q).map((row, idx) => {
      let vols = []
      row.vols.forEach((v, k) => {
        v = { year: Number(k.split("-")[0]), month: Number(k.split("-")[1]), vol: v.vol, price: v.price }
        vols.push(v)
      })
      return { ...row, idx: idx, vols: vols }
    })

    let resultVols = []
    q.result.vols.forEach((v, k) => {
      v = { year: Number(k.split("-")[0]), month: Number(k.split("-")[1]), vol: v.vol, price: v.price }
      resultVols.push(v)
    })

    let quoteData = {
      constId: props.constId,
      termFrom: new Date(q.termFrom),
      termTo: new Date(q.termTo),
      initialize: false,
      details: details,
      summary: summarys,
      result: { ...q.result, vols: resultVols }
    }

    ExcelService.download("stage", quoteData)
      .catch(error => {
        setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
      })
  }

  const exportCsvFile = () => {
    const q = alignDetails({ ...quote })
    let details = q.details.concat().map((row) => {
      let vols = []
      row.vols.forEach((v, k) => {
        v = { year: Number(k.split("-")[0]), month: Number(k.split("-")[1]), vol: v.vol, price: v.price }
        vols.push(v)
      })
      return { ...row, vols: vols }
    })

    let s = summary(q)
    console.log(s)
    let summarys = s.map((row, idx) => {
      let vols = []
      row.vols.forEach((v, k) => {
        v = { year: Number(k.split("-")[0]), month: Number(k.split("-")[1]), vol: v.vol, price: v.price }
        vols.push(v)
      })
      return { ...row, idx: idx, vols: vols }
    })

    let resultVols = []
    q.result.vols.forEach((v, k) => {
      v = { year: Number(k.split("-")[0]), month: Number(k.split("-")[1]), vol: v.vol, price: v.price }
      resultVols.push(v)
    })

    let quoteData = {
      constId: props.constId,
      termFrom: new Date(q.termFrom),
      termTo: new Date(q.termTo),
      initialize: false,
      details: details,
      summary: summarys,
      result: { ...q.result, vols: resultVols }
    }

    CsvService.exportCsv(quoteData)
      .catch(error => {
        setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
      })
  }

  const importCsvFile = (file) => {
    CsvService.importCsv(props.constId, file).then((response) => {
      let data = response.data
      if (data.status === "ERROR") {
        let msg = ""
        data.messageList.forEach((m) => {
          msg += m + "\n"
        })
        setError({ msg: msg, open: true, title: "エラー" })
      } else {
        let q = data.data

        setChangeSchedule(q.initialize)
        let quote = buildQuoteDetail(q)
        setQuote(quote)
      }
    }).catch(error => {
      console.log(error)
      setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
    })
  }

  const buildQuoteDetail = (quote) => {
    let details = quote.details.concat()
    let constInfo = constMap.get(props.constId)


    details.forEach((row, idx) => {
      row.startDate = !row.startDate ? new Date(quote.termFrom) : new Date(row.startDate)
      row.endDate = !row.endDate ? new Date(quote.termTo) : new Date(row.endDate)
      row.idx = idx

      const contractDate = row.contractDate ? new Date(row.contractDate) : new Date()

      let taxRate = getTaxRate(contractDate, row.taxType)
      row.taxRate = taxRate

      if (!row.leaf) {
        row.expandChild = false
      }

      if (row.vols) {
        let vols = new Map()
        row.vols.forEach((v) => {
          vols.set(v.year + "-" + v.month, { vol: v.vol, price: v.price })
        })
        row.vols = vols
      }
    })

    let resultVols = new Map()
    quote.result.vols.forEach((v) => {
      resultVols.set(v.year + "-" + v.month, { vol: v.vol, price: v.price })
    })
    quote.result.vols = resultVols

    return alignDetails(quote)
  }

  const getTaxRate = (date, taxType) => {
    if (!date || taxType === null) {
      return null
    }

    let aliveInfo = taxInfo.filter(t => {
      return t.type === taxType && t.date <= date
    })
    if (aliveInfo.length === 0) {
      return 0
    }

    let rate = aliveInfo.reduce((t1, t2) => {
      return t1.date > t2.date ? t1 : t2
    }).rate

    return rate
  }

  const alignDetails = (data) => {
    if (isErrorTermRange(data.termFrom, data.termTo)) {
      return data
    }

    const rmRange = calcMonthRange(data).map((o) => {
      return o.year + "-" + o.month
    })

    data.details.forEach((row) => {
      const vols = new Map()

      rmRange.forEach((k) => {
        console.log(row.vols)
        vols.set(k, row.vols.get(k) ? row.vols.get(k) : { vol: 0, price: 0 })
      })

      row.vols = vols
    })

    const vols = new Map()
    rmRange.forEach((k) => {
      vols.set(k, data.result.vols.get(k) ? data.result.vols.get(k) : { vol: 0, price: 0 })
    })
    data.result.vols = vols
    return data
  }

  const isErrorTermRange = (termFrom, termTo) => {
    if (!termFrom || !termTo) {
      return true
    }

    if (termFrom > termTo) {
      return true
    }
    return false
  }

  useEffect(() => {
    setTabIndex(0)
    setConstInfo(constMap.get(props.constId))
  }, [constMap, props.constId])

  useEffect(() => {
    if (save) {
      if (quote.details.length === 0) {
        setError({ msg: `保存対象のデータはありません。`, open: true, title: 'エラー' })
      } else {
        setOpen(true)
      }
      setSave(false)
    }
  }, [save])

  useEffect(() => {
    if (outputExcel) {
      if (quote.details.length === 0) {
        setError({ msg: `出力対象のデータはありません。`, open: true, title: 'エラー' })
      } else {
        exportExcel()
      }
      setOutputExcel(false)
    }
  }, [outputExcel])

  useEffect(() => {
    if (exportCsv) {
      if (quote.details.length === 0) {
        setError({ msg: `出力対象のデータはありません。`, open: true, title: 'エラー' })
      } else {
        exportCsvFile()
      }
      setExportCsv(false)
    }
  }, [exportCsv])

  if (!props.constId) {
    return (<React.Fragment />)
  }
  return (
    <MainContent className="ConstManageChoiser" style={props.style} >
      <FileUploadDialog
        dialogTitle="進捗データのインポート"
        open={importCsv}
        onChoiseFile={(acceptedFile) => {
          importCsvFile(acceptedFile)
          setImportCsv(false)
        }}
        onCancel={() => { setImportCsv(false) }}
        onClose={() => { setImportCsv(false) }}
      />

      <ConfirmationDialog
        dialogTitle="保存"
        bodyText={"このデータを保存します。よろしいですか？"}
        open={open}
        onOk={() => {
          saveData()
          setOpen(false)
        }}
        onCancel={() => { setOpen(false) }}
        onClose={() => { setOpen(false) }}
      />
      <TabContext value={tabIndex} style={{ position: "relative", display: "flex", flexDirection: "column", }}>
        <div style={{ position: "relative", display: "flex", justifyContent: "left", alignItems: "flex-end", borderBottom: "1px solid #ccc", }}>
          <ConstPanel value={constInfo} quote={quote} style={{ marginRight: "20px" }}
            onChangeQuote={(quote) => {
              alignDetails(quote)
              setQuote(quote)
            }} />
          <TabList
            style={{ position: "relative", }}
            indicatorColor="primary"
            textColor="primary"
            selectionFollowsFocus={true}
            value={tabIndex}

            onChange={(event, v) => {
              setTabIndex(v)
            }}
          >
            <Tab label="工事情報" style={{ backgroundColor: "#fee", }} value={0} />
            {quote.termFrom && quote.termTo ?
              <Tab label="工程表" style={{ backgroundColor: "#efe", }} value={1} />
              : null
            }
            {quote.termFrom && quote.termTo ?
              <Tab label="出来高表" style={{ backgroundColor: "#eef", }} value={2} />
              : null
            }
            <Tab label="工事写真" style={{ backgroundColor: "#fef", }} value={3} />
            <Tab label="工事日報" style={{ backgroundColor: "#eff", }} value={4} />
          </TabList>
        </div>
        <div style={{ position: "relative", height: "calc(100% - 120px)", display: "block", top: 0, bottom: 0, overflowY: "auto", }}>
          <StyledTabPanel value={0} style={{
            position: "relative", display: tabIndex === 0 ? "block" : "none"
          }}>
            <div></div>
          </StyledTabPanel>
          <StyledTabPanel value={1} style={{
            position: "relative", display: tabIndex === 1 ? "block" : "none"
          }}>
            <ConstSchedule constId={props.constId} value={quote} onChangeSchedule={(s, changeFlag) => {
              if (changeFlag) {
                setChangeSchedule(true)
              }
              setQuote(s)
            }} />
          </StyledTabPanel>
          <StyledTabPanel value={2} style={{
            position: "relative", display: tabIndex === 2 ? "block" : "none"
          }}>
            <ConstStagePlan constId={props.constId} value={{ quote: quote }} resetPlan={changeSchedule}
              onResetPlan={() => { setChangeSchedule(false) }}
              onChangeStagePlan={(s) => { setQuote(s) }} />
          </StyledTabPanel>
        </div>
      </TabContext>
    </MainContent>
  )
}

const ConstPanel = (props) => {
  const [inputTermFrom, setInputTermFrom] = useState(false)
  const [inputTermTo, setInputTermTo] = useState(false)

  if (!props.value || !props.value.constId) {
    return (<React.Fragment />)
  }

  const termFrom = props.quote.termFrom ? new Date(props.quote.termFrom).toLocaleDateString('sv-SE') : ""
  const termTo = props.quote.termTo ? new Date(props.quote.termTo).toLocaleDateString('sv-SE') : ""

  let imgPath = "/ConstManage/v1/constphotos/projectPhoto?constId=" + props.value.constId
  return (
    <div id={'ANCHOR-' + props.value.constId} className="ConstPanel" style={props.selected ? { ...props.style, backgroundColor: "#cfc" } : { ...props.style }}>
      <div className="ConstImageContainer">
        <CardMedia className="ConstImage" image={imgPath} alt="image" />
      </div>
      <div >
        <CardContent style={{ padding: "0px 2px 2px 10px", width: "400px", maxWidth: "400px" }}>
          <div className="ConstId">{props.value.constId}</div>
          <Tooltip title={props.value.name}>
            <div className="ConstName">{props.value.name}</div>
          </Tooltip>
          <hr style={{ marginTop: "2px", marginBottom: "4px" }} />
          <div className="ConstTerm">
            {inputTermFrom ?
              <CellInput id={"constTermFrom"} type="date" value={termFrom} style={{ backgroundColor: termFrom ? "transparent" : "red" }}
                onChange={(event) => {
                  props.onChangeQuote({ ...props.quote, termFrom: new Date(event.target.value) })
                }}
                onBlur={() => {
                  setInputTermFrom(false)
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    setInputTermFrom(false)
                  }
                }}
              />
              : <JpnDate value={termFrom}
                required={true}
                style={{ color: "black", cursor: "pointer" }}
                onClick={() => {
                  setInputTermFrom(true)
                  setTimeout(() => {
                    document.getElementById("constTermFrom").focus()
                  }, 100)
                }}
              />
            }
            <p>　〜　</p>
            {inputTermTo ?
              <CellInput id={"constTermTo"} type="date" value={termTo} style={{ backgroundColor: termTo ? "transparent" : "red" }}
                onChange={(event) => {
                  props.onChangeQuote({ ...props.quote, termTo: new Date(event.target.value) })
                }}
                onBlur={() => {
                  setInputTermTo(false)
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    setInputTermTo(false)
                  }
                }}
              />
              : <JpnDate value={termTo}
                required={true}
                style={{ color: "black", cursor: "pointer" }}
                onClick={() => {
                  setInputTermTo(true)
                  setTimeout(() => {
                    document.getElementById("constTermTo").focus()
                  }, 100)
                }} />
            }
          </div>
          <div style={{ display: "flex", marginTop: "5px" }}>
            <div className="SalesStaff">
              <div className="Label">営業担当</div>
              <div className="Name">{props.value.salesStaff}</div>
            </div>
            <div className="ConstructionStaff">
              <div className="Label">工事担当</div>
              <div className="Name">{props.value.staff}</div>
            </div>
          </div>
          <div className="Process" style={{ backgroundColor: props.value.process === "工事進行中" ? "lightgreen" : "yellow" }}>{props.value.process}</div>
        </CardContent>
      </div>
    </div>
  )
}

export default ConstManageChoiser
