import CellInput from '../../common/CellInput'

import React, { useState, useEffect } from 'react'

const Check = (props) => {
  const [check, setCheck] = useState(props.value)
  useEffect(() => {
    setCheck({ date: props.value?.date ? new Date(props.value.date) : null, matter: props.value ? props.value.matter : null })
  }, [props.value])

  const fmt = (dt) => {
    if (!dt) return null
    if (typeof (dt) === 'string') {
      dt = new Date(dt)
    }
    let y = dt.getFullYear()
    let m = ("00" + (dt.getMonth() + 1)).slice(-2)
    let d = ("00" + dt.getDate()).slice(-2)
    return `${y}-${m}-${d}`
  }

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <CellInput type="date" style={{ width: "150px" }} value={fmt(check?.date)} readOnly={props.readOnly}
        onInput={(event) => {
          let date = (event.target.value) ? new Date(event.target.value) : null
          let val = { ...check, date: date }
          setCheck(val)
          props.onChangeCheck(val)
        }} />
      <div style={{ display: "flex" }}>
        <span style={{ marginRight: "2px", }} className="miniLabel">内容</span>
        <CellInput type="text" style={{ width: "200px" }} value={check?.matter} readOnly={props.readOnly}
          onChange={(event) => {
            let val = { ...check, matter: event.target.value }
            setCheck(val)
            props.onChangeCheck(val)
          }} />
      </div>
    </div >
  )
}

export default Check