import './ConstPhotos.css'

import InfoBox from '../../common/InfoBox'
import { RedIconButton } from '../../common/ColorButton'
import { imageUrl, thumbUrl } from '../../../services/Config'
import { isSmartPhone } from '../../common/Responsive'
import ConstReportThumbnail from './ConstReportThumbnail'

import React, { useState, useEffect, useRef } from 'react'
import Viewer from 'react-viewer'
import AddIcon from '@mui/icons-material/Add'
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Paper } from '@mui/material'

const ConstPhotos = (props) => {
    const PHOTO_MAX_COUNT = 4
    const [list, setList] = useState((props.value ? props.value : []).filter(
        (photo) => { return photo && photo._id }
    ))
    const [viewSource, setViewSource] = useState(null)
    const [open, setOpen] = useState(false)
    const [choises, setChoises] = useState((props.value ? props.value : []).filter(
        (photo) => { return photo && photo._id }
    ).map((photo) => {
        return photo._id
    }))

    useEffect(() => {
        setList((props.value ? props.value : []).filter(
            (photo) => { return photo && photo._id }
        ))
        setChoises((props.value ? props.value : []).filter(
            (photo) => { return photo && photo._id }
        ))
    }, [props.value])

    const handleClose = () => { setOpen(false) }

    const handleChange = () => {
        let newList = []

        choises.map((id) => {
            let photo = props.todayPhotos.find((p) => {
                return p._id === id
            })
            if (photo) {
                photo.index = newList.length
                newList.push(photo)
            }
        })

        setList(newList)
        props.onChange(newList)
        setOpen(false)
    }

    return (
        <div className="ConstPhotos constRow" style={{ display: "flex", flexDirection: "column", minHeight: "150px" }}>
            <Dialog
                className="PhotoDialog"
                open={open}
                fullScreen={isSmartPhone()}
                onClose={handleClose}
                PaperProps={isSmartPhone() ? {} : {
                    sx: {
                        maxHeight: "500px"
                    }
                }}>
                <DialogTitle>写真選択</DialogTitle>
                <DialogContent style={{ backgroundColor: "lightgray" }}>
                    {props.todayPhotos.map((photo) => {
                        const srcS = thumbUrl + "/s/?photoId=" + photo._id
                        return (
                            <Paper style={{
                                backgroundColor: choises.indexOf(photo._id) >= 0 ? "#fdd" : "white",
                                padding: "5px", margin: "5px", display: "flex", alignItems: "start",
                            }} key={photo._id}
                                onClick={() => {
                                    if (choises.indexOf(photo._id) >= 0) {
                                        // choisesから当該データを削除
                                        setChoises(choises.filter((id) => id !== photo._id))
                                    } else if (choises.length < PHOTO_MAX_COUNT) {
                                        setChoises(props.todayPhotos.filter((p) => {
                                            return (choises.indexOf(p._id) >= 0 || p._id === photo._id)
                                        }).map((photo) => photo._id))
                                    }
                                }}
                            >
                                <img alt="" style={{
                                    border: ("2px solid " + choises.indexOf(photo._id) >= 0 ? "red" : "white"),
                                    display: "block",
                                    objectFit: "contain"
                                }} src={srcS} loading={"lazy"} />
                                <div style={{ width: "400px" }}>
                                    <div>
                                        <div style={{ display: "inline-block", width: "80px", textAlign: "right" }}>工種：</div>
                                        <span>{photo.constClassName}</span>
                                    </div>
                                    <div>
                                        <div style={{ display: "inline-block", width: "80px", textAlign: "right" }}>工種詳細：</div>
                                        <span>{photo.constTypeName}</span>
                                    </div>
                                    <div>
                                        <div style={{ display: "inline-block", width: "80px", textAlign: "right" }}>回数：</div>
                                        <span>{photo.floor}{photo.floor ? "階" : ""}</span>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "start" }}>
                                        <div style={{ display: "inline-block", width: "80px", textAlign: "right" }}>詳細：</div>
                                        <span style={{ display: "inline-block", whiteSpace: "pre-wrap" }}>{photo.description}</span>
                                    </div>
                                </div>
                            </Paper>
                        )
                    })}
                    {props.todayPhotos.length === 0 ? <div className="Blank">この日に撮影された写真はありません</div> : <React.Fragment />}
                </DialogContent>
                <DialogActions>
                    <div style={{ position: "absolute", left: "10px", display: "block", width: "300px" }}>{choises.length}/{PHOTO_MAX_COUNT}</div>
                    <Button color="inherit" onClick={handleClose}>キャンセル</Button>
                    <Button onClick={handleChange}>確定</Button>
                </DialogActions>
            </Dialog>
            <InfoBox title="毎日の施工写真" style={{ width: "100%" }} />
            <div className="PhotoPanel constCell" >
                {!props.readOnly ?
                    <div style={{ top: "10px", right: "30px", position: "absolute", display: "block" }}>
                        <RedIconButton onClick={() => {
                            setOpen(true)
                        }} >
                            <AddIcon />
                        </RedIconButton>
                    </div> : <React.Fragment />
                }
                <Viewer
                    visible={viewSource !== null}
                    downloadable={true}
                    loop={false}
                    onClose={() => { setViewSource(null) }}
                    images={(list ? list : []).map((photo) => {
                        return { src: thumbUrl + "/l/?photoId=" + photo._id, alt: '', downloadUrl: imageUrl + "/?photoId=" + photo._id }
                    })}
                    activeIndex={viewSource}
                />
                {
                    list.map((photo, index) => {
                        return (
                            <PhotoThumbnailItem key={photo._id} value={photo} index={index}
                                onClick={(index) => { setViewSource(index) }} />
                        )
                    })
                }
            </div>
        </div >
    )
}

const PhotoThumbnailItem = (props) => {
    let index = props.index
    let photo = props.value

    return (
        <div key={props.key} style={{ marginLeft: "10px" }}>
            <ConstReportThumbnail size="l" index={index} value={photo} onClickPicture={(photoIndex) => {
                props.onClick(photoIndex)
            }} />
        </div>
    )
}

export default ConstPhotos