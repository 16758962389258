import "./DroppableBar.css"

import { photoSelectState } from '../hooks/PhotoSelectState'

import { React, useRef } from 'react'
import { useRecoilState } from 'recoil'

const DroppableBar = (props) => {
    const [photoSelect, setPhotoSelect] = useRecoilState(photoSelectState)
    const inputElement = useRef(null)

    return (
        <div className={"DropableBar"} key={props.key} ref={inputElement} dropzone="copy" style={{ display: "block", position: "relative", ...props.style }}
            onDrop={(event) => {
                inputElement.current.style.backgroundColor = null
                props.onDropPhoto(photoSelect, props.insertPos)
                setPhotoSelect({ type: '', photos: new Map() })
                event.preventDefault()
            }}
            onDragOver={(event) => {
                event.preventDefault()
            }}
            onDragEnter={(event) => {
                inputElement.current.style.backgroundColor = "green"
                inputElement.current.style.opacity = 1.0
            }}
            onDragLeave={(event) => {
                inputElement.current.style.backgroundColor = null
                inputElement.current.style.opacity = 0.5
            }}
        >
            {props.children}
        </div>
    )
}

export default DroppableBar
