import './MorningMeeting.css'

import InfoBox from '../../common/InfoBox'
import TimeInput from "../../common/TimeInput"
import CellInput from "../../common/CellInput"
import LimitedTextarea from '../../common/LimitedTextarea'

import React, { useState } from 'react'

const MorningMeeting = (props) => {
  const [time, setTime] = useState({
    from: props.from ? props.from : "",
    to: props.to ? props.to : "",
    companyCount: props.companyCount ? props.companyCount : 0,
    newEntrants: props.newEntrants ? props.newEntrants : 0,
    mainContent: props.mainContent ? props.mainContent : "",
  })
  return (
    <div className="MorningMeeting" >
      <InfoBox title="朝礼記録" />
      <div className="constCell" style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
        <div style={{ position: "relative", display: "flex", alignItems: "middle", marginLeft: "5px", marginTop: "2px" }}>
          <div style={{ marginTop: "3px" }}>時間</div>
          <div style={{ width: "50px" }}>
            <TimeInput
              value={time.from.time}
              readOnly={props.readOnly}
              onChange={(e) => {
                let tm = { ...time, from: { time: e.target.value } }
                setTime(tm)
                props.onChangeValue(tm)
              }}
            />
          </div>
          <div style={{ marginTop: "3px", marginLeft: "5px", marginRight: "5px" }}>
            〜
          </div>
          <div style={{ width: "50px" }}>
            <TimeInput
              value={time.to.time}
              readOnly={props.readOnly}
              onChange={(e) => {
                let tm = { ...time, to: { time: e.target.value } }
                setTime(tm)
                props.onChangeValue(tm)
              }}
            />
          </div>
        </div>
        <div style={{ position: "relative", display: "flex", marginLeft: "5px", marginTop: "2px", }}>
          <div style={{ marginTop: "3px" }}>参加会社数</div>
          <div style={{ width: "30px" }}>
            <CellInput type="number"
              value={time.companyCount}
              readOnly={props.readOnly}
              step="0.1"
              style={{ width: "30px", textAlign: "right" }}
              onChange={(e) => {
                let v = Number(e.target.value)
                if (v > 100 || v < 0) {
                  return
                }
                v = Math.round(v * 10) / 10
                e.target.value = v
                setTime({ ...time, companyCount: v })
              }}
              onBlur={(e) => {
                props.onChangeValue(time)
              }}
            />
          </div>
          <div style={{ marginLeft: "10px", marginTop: "3px" }}>新規入場者数</div>
          <div style={{ width: "30px" }}>
            <CellInput type="number"
              value={time.newEntrants}
              readOnly={props.readOnly}
              step="0.1"
              style={{ width: "30px", textAlign: "right" }}
              onChange={(e) => {
                let v = Number(e.target.value)
                if (v > 100 || v < 0) {
                  return
                }
                v = Math.round(v * 10) / 10
                e.target.value = v
                setTime({ ...time, newEntrants: v })
              }}
              onBlur={(e) => {
                props.onChangeValue(time)
              }}
            />
          </div>
        </div>
        <div style={{ position: "relative", display: "flex", flexDirection: "column", marginTop: "5px" }}>
          <div style={{ marginLeft: "5px", whiteSpace: "wrap" }}>朝礼の主要内容(危険場所・重要伝達事項・指示事項)&nbsp;&nbsp;&nbsp;<br className="crCtrl" />現場内全体危険予知</div>
          <div>
            <LimitedTextarea
              maxLines={3}
              maxCharsPerLine={60}
              initialText={time.mainContent}
              readOnly={props.readOnly}
              style={{ width: "420px", margin: "2px" }}
              onChange={(text) => {
                setTime({ ...time, mainContent: text })
              }}
              onBlur={(e) => {
                props.onChangeValue(time)
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MorningMeeting